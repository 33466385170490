import { snakeCase } from "lodash";
import { TrendingUpIcon } from "lucide-react";
import { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

import { Button } from "~/components/ui/button.tsx";
import { OnboardingCheckStatus } from "~/routes/suivi/components/OnboardingCheckStatus.tsx";
import { OnboardingEplTest } from "~/routes/suivi/components/OnboardingEplTest.tsx";
import { OnboardingPilotest } from "~/routes/suivi/components/OnboardingPilotest.tsx";
import { OnboardingSelection } from "~/routes/suivi/components/OnboardingSelection.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";

export interface OnboardingSettings {
  selection: {
    name: string | null;
    customName?: string;
  };
  pilotest: {
    enabled: boolean;
    mode?: "auto" | "manual";
    email?: string;
    password?: string;
    attempts?: ExerciseAttempt[];
  };
  eplTest: {
    enabled: boolean;
    apiKey?: string;
  };
}

export interface OnboardingSettingsChecks {
  global: "draft" | "pending" | "error";
  pilotestConnectivity: "success" | "error" | "idle";
  eplTestConnectivity: "success" | "error" | "idle";
}

export const OnboardingPage: FC = () => {
  const navigate = useNavigate();

  const { settings, registerSettings } = useSettings();

  const {
    saveAttempts,
    checkConnectivityWithPilotest,
    checkConnectivityWithEplTest,
  } = useAttempts();

  const { enablePilotestAutoSync } = usePilotestCredentials();

  const [onboardingSettings, setOnboardingSettings] =
    useImmer<OnboardingSettings>({
      selection: {
        name: null,
      },
      pilotest: { enabled: false },
      eplTest: { enabled: false },
    });

  const [onboardingSettingsChecks, setOnboardingSettingsChecks] =
    useImmer<OnboardingSettingsChecks>({
      global: "draft",
      pilotestConnectivity: "idle",
      eplTestConnectivity: "idle",
    });

  const handleValidate = () => {
    const promises: Promise<unknown>[] = [];

    setOnboardingSettingsChecks((draft) => {
      draft.global = "pending";
    });

    if (
      onboardingSettings.pilotest.enabled &&
      onboardingSettings.pilotest.mode === "auto"
    ) {
      promises.push(
        checkConnectivityWithPilotest(
          onboardingSettings.pilotest.email ?? "",
          onboardingSettings.pilotest.password ?? "",
        )
          .then(() => {
            setOnboardingSettingsChecks((draft) => {
              draft.pilotestConnectivity = "success";
            });

            return true;
          })
          .catch(() => {
            setOnboardingSettingsChecks((draft) => {
              draft.pilotestConnectivity = "error";
            });

            return false;
          }),
      );
    }

    if (onboardingSettings.eplTest.enabled) {
      promises.push(
        checkConnectivityWithEplTest(onboardingSettings.eplTest.apiKey ?? "")
          .then(() => {
            setOnboardingSettingsChecks((draft) => {
              draft.eplTestConnectivity = "success";
            });

            return true;
          })
          .catch(() => {
            setOnboardingSettingsChecks((draft) => {
              draft.eplTestConnectivity = "error";
            });

            return false;
          }),
      );
    }

    Promise.all(promises)
      .then((res) => {
        if (res.some((r) => r !== true)) {
          return setOnboardingSettingsChecks((draft) => {
            draft.global = "error";
          });
        }

        executeOnboard();
      })
      .catch(() =>
        setOnboardingSettingsChecks((draft) => {
          draft.global = "error";
        }),
      );
  };

  const executeOnboard = () => {
    const promises: Promise<void>[] = [];

    promises.push(
      registerSettings({
        selection:
          onboardingSettings.selection.name === "other"
            ? `other:${snakeCase(onboardingSettings.selection.customName)}`
            : onboardingSettings.selection.name,
        epl_api_key: onboardingSettings.eplTest.apiKey,
      }),
    );

    if (onboardingSettings.pilotest.enabled) {
      if (onboardingSettings.pilotest.mode === "manual") {
        promises.push(
          saveAttempts(
            "input_csv",
            "pilotest",
            onboardingSettings.pilotest.attempts ?? [],
          ),
        );
      }

      if (onboardingSettings.pilotest.mode === "auto") {
        promises.push(
          new Promise((resolve) => {
            enablePilotestAutoSync(
              onboardingSettings.pilotest.email ?? "",
              onboardingSettings.pilotest.password ?? "",
            );

            resolve();
          }),
        );
      }
    }

    Promise.all(promises).then(() => {
      navigate("/suivi/stats?forceSync=true");
    });
  };

  const isDisabled = useMemo(() => {
    if (onboardingSettingsChecks.global === "pending") {
      return true;
    }

    // Selection must be set…
    if (
      !onboardingSettings.selection.name ||
      (onboardingSettings.selection.name === "other" &&
        (!onboardingSettings.selection.customName ||
          onboardingSettings.selection.customName.trim().length === 0))
    ) {
      return true;
    }

    // At least one provider must be selected…
    if (
      !onboardingSettings.pilotest.enabled &&
      !onboardingSettings.eplTest.enabled
    ) {
      return true;
    }

    // For Pilotest, a sync mode must be selected…
    if (
      onboardingSettings.pilotest.enabled &&
      !onboardingSettings.pilotest.mode
    ) {
      return true;
    }

    // For Pilotest, if auto sync mode is enabled, email and password must be filled…
    if (
      onboardingSettings.pilotest.enabled &&
      onboardingSettings.pilotest.mode === "auto" &&
      (!onboardingSettings.pilotest.email ||
        !onboardingSettings.pilotest.password)
    ) {
      return true;
    }

    // For Pilotest, if manual sync mode is enabled, .csv file must be parsed…
    if (
      onboardingSettings.pilotest.enabled &&
      onboardingSettings.pilotest.mode === "manual" &&
      (!onboardingSettings.pilotest.attempts ||
        onboardingSettings.pilotest.attempts.length === 0)
    ) {
      return true;
    }

    // For EPLtest, API Key must be provided…
    if (
      onboardingSettings.eplTest.enabled &&
      !onboardingSettings.eplTest.apiKey
    ) {
      return true;
    }

    return false;
  }, [onboardingSettings, onboardingSettingsChecks]);

  useEffect(() => {
    if (settings) {
      return navigate("/suivi/stats");
    }
  }, [navigate, settings]);

  if (settings) {
    return null;
  }

  return (
    <div className="w-full sm:w-1/3 mx-auto">
      <div className="flex flex-col items-center justify-center">
        <p>
          <TrendingUpIcon className="text-blue-600" size={64} />
        </p>
        <h3 className="text-xl max-w-lg text-center font-medium mt-4">
          Suivez votre progression Pilotest et/ou EPLtest.
        </h3>
      </div>
      <div className="mt-8 space-y-8 w-full">
        <OnboardingSelection
          settings={onboardingSettings}
          onChangeSettings={setOnboardingSettings}
        />
        <div>
          <p className="text-sm text-slate-600">
            Sur quel(s) site(s) vous entrainez vous ?
          </p>
          <div className="space-y-3 mt-2">
            <OnboardingPilotest
              settings={onboardingSettings}
              onChangeSettings={setOnboardingSettings}
            />
            <OnboardingEplTest
              settings={onboardingSettings}
              onChangeSettings={setOnboardingSettings}
            />
          </div>
        </div>
      </div>
      {onboardingSettingsChecks.global !== "draft" && (
        <div className="my-6 border rounded px-4 py-3 text-sm text-slate-500">
          <p className="font-medium">Contrôles en cours…</p>
          <div className="flex flex-col mt-2 gap-1.5">
            {onboardingSettings.pilotest.enabled && (
              <>
                {onboardingSettings.pilotest.mode === "manual" && (
                  <OnboardingCheckStatus
                    name="Pilotest : import manuel .csv"
                    status="success"
                  />
                )}
                {onboardingSettings.pilotest.mode === "auto" && (
                  <OnboardingCheckStatus
                    name="Pilotest : connexion à Pilotest"
                    status={onboardingSettingsChecks.pilotestConnectivity}
                  />
                )}
              </>
            )}
            {onboardingSettings.eplTest.enabled && (
              <OnboardingCheckStatus
                name="EPLtest : connexion à EPLtest"
                status={onboardingSettingsChecks.eplTestConnectivity}
              />
            )}
          </div>
        </div>
      )}
      <div className="my-6">
        <Button
          size="lg"
          className="text-lg w-full h-12"
          onClick={handleValidate}
          disabled={isDisabled}
        >
          C'est parti !
        </Button>
      </div>
    </div>
  );
};
