import { Enums } from "~/supabase.types.ts";

export const getFixedColumnSpan = (
  hiddenElements: Enums<"suivi_table_element">[] = [],
) => {
  const dynamicColumnsLength = hiddenElements.filter((col) =>
    (
      [
        "column_general",
        "column_7_days",
        "column_3_days",
        "column_today",
      ] as Enums<"suivi_table_element">[]
    ).includes(col),
  ).length;

  return (
    3 +
    dynamicColumnsLength * 2 +
    (hiddenElements.includes("column_last_attempt") ? 1 : 0)
  );
};
