import { FC } from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Favorites } from "~/routes/root/components/Favorites.tsx";
import { SnowFall } from "~/routes/root/components/SnowFall.tsx";
import { Footer } from "~/routes/suivi/components/Footer.tsx";

export const Root: FC = () => {
  const { user } = useCurrentUser();

  return (
    <>
      <SnowFall />
      <div className="flex flex-col items-center justify-between h-full">
        <div className="flex flex-col justify-center flex-1 w-full">
          <div className="flex flex-col items-center justify-center">
            <span className="text-6xl">👋</span>
            <p className="text-2xl font-medium mt-6 text-slate-600">
              Bienvenue{" "}
              <span className="text-slate-800 underline underline-offset-4">{`${user?.user_metadata?.full_name ?? user.user_metadata?.email ?? ""}`}</span>{" "}
              !
            </p>
            <p className="text-center text-slate-400 text-sm mt-2 w-10/12 sm:w-full">
              Utilisez le menu de gauche pour naviguer entre les différents
              outils.
            </p>
          </div>
          <Favorites />
        </div>
        <Footer />
      </div>
    </>
  );
};
