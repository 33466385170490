import { FC } from "react";

interface ExercisesResultsHeaderProps {
  title: string;
  subtitle?: string;
}

export const ExercisesResultsHeader: FC<ExercisesResultsHeaderProps> = ({
  title,
  subtitle,
}) => (
  <div className="sm:col-span-2 hidden last-of-type:flex sm:flex flex-col items-end px-2.5 py-3">
    <p className="text-[10px] text-slate-400 tracking-wider uppercase">
      depuis
    </p>
    <p className=" font-medium uppercase">{title}</p>
    {subtitle && (
      <p className="text-[10px] uppercase text-slate-400 tracking-wider">
        {subtitle}
      </p>
    )}
  </div>
);
