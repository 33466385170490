import clsx from "clsx";
import { noop } from "lodash";
import { EditIcon, TrashIcon } from "lucide-react";
import { FC, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import { RadioGroup, RadioGroupItem } from "~/components/ui/radio-group.tsx";
import { Textarea } from "~/components/ui/textarea.tsx";
import { QUIZLET_CATEGORIES } from "~/routes/quizlet/Questions.tsx";
import { supabase } from "~/supabase.ts";
import { Enums, Tables } from "~/supabase.types.ts";
import { formatDate } from "~/utils/formatDate.ts";

interface AdminQuestion {
  question: Tables<"quizlet_questions">;
  onRefreshQuestion: () => void;
}

export const AdminQuestion: FC<AdminQuestion> = ({
  question,
  onRefreshQuestion,
}) => {
  const [editMode, setEditMode] = useState(false);

  const [questionDraft, setQuestionDraft] = useState(question.question);
  const [answersDraft, setAnswersDraft] = useState(question.answers);
  const [correctAnswerDraft, setCorrectAnswerDraft] = useState(
    question.correct_answer,
  );

  const handleOnUpdateQuestion = async () => {
    await supabase
      .from("quizlet_questions")
      .update({
        question: questionDraft,
        answers: answersDraft,
        correct_answer: correctAnswerDraft,
      })
      .eq("id", question.id);

    onRefreshQuestion();
  };

  const handleOnCategoriseQuestion = (
    questionId: string,
    category: Enums<"quizlet_category">,
  ) => {
    supabase
      .from("quizlet_questions")
      .update({ category })
      .eq("id", questionId)
      .then(noop);
  };

  const handleOnDeleteQuestion = async () => {
    if (confirm("Souhaitez-vous vraiment supprimer cette question ?")) {
      await supabase.from("quizlet_questions").delete().eq("id", question.id);

      onRefreshQuestion();
    }
  };

  return (
    <div className="relative flex gap-8 px-4 rounded odd:py-2 odd:bg-slate-50">
      <div className="w-2/12 text-sm">
        {question.category ? (
          QUIZLET_CATEGORIES[question.category]
        ) : (
          <select
            className="text-black text-sm py-1 rounded w-full"
            onChange={(evt) =>
              handleOnCategoriseQuestion(
                question.id,
                evt.currentTarget.value as Enums<"quizlet_category">,
              )
            }
          >
            <option value="">Catégoriser…</option>
            {Object.entries(QUIZLET_CATEGORIES).map(
              ([categoryKey, categoryValue]) => (
                <option key={`category-${categoryKey}`} value={categoryKey}>
                  {categoryValue}
                </option>
              ),
            )}
          </select>
        )}
      </div>

      <div className="w-5/12">
        {editMode ? (
          <Textarea
            value={questionDraft}
            onChange={(evt) => setQuestionDraft(evt.currentTarget.value)}
            className="h-32"
          />
        ) : (
          <>
            <p className="text-sm">{question.question}</p>
            <p className="text-xs mt-1 text-slate-400">
              par @{question.author}, le {formatDate(question.created_at)}
            </p>
          </>
        )}
      </div>
      <div className="w-5/12 text-sm">
        {editMode ? (
          <RadioGroup
            className="flex flex-col"
            value={correctAnswerDraft}
            onValueChange={setCorrectAnswerDraft}
          >
            {answersDraft.map((answer, idx) => (
              <div
                key={`draft-answer-${idx}`}
                className="flex items-center gap-2"
              >
                <RadioGroupItem value={answer} />
                <Input
                  value={answer}
                  onChange={(evt) => {
                    const updatedAnswers = [...answersDraft];
                    updatedAnswers[idx] = evt.currentTarget.value;
                    setAnswersDraft(updatedAnswers);
                  }}
                  className={clsx(
                    correctAnswerDraft === answer && "font-semibold",
                  )}
                />
              </div>
            ))}
          </RadioGroup>
        ) : (
          <ul>
            {question.answers.map((answer, idx) => (
              <li
                key={`answer-${idx}`}
                className={clsx(
                  answer === question.correct_answer && "font-bold",
                )}
              >
                {answer}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="absolute top-0 -right-8 flex flex-col gap-2">
        <Button
          size="icon-sm"
          variant={editMode ? "default" : "outline"}
          onClick={() => {
            if (editMode) {
              handleOnUpdateQuestion();
            }

            setEditMode(!editMode);
          }}
        >
          <EditIcon className="w-3 h-3" />
        </Button>
        <Button
          size="icon-sm"
          variant="outline"
          onClick={handleOnDeleteQuestion}
        >
          <TrashIcon className="w-3 h-3 text-red-500" />
        </Button>
      </div>
    </div>
  );
};
