export const getInterpolatedY = (
  data: {
    x: number;
    y: number;
  }[],
  xVal: number,
): number => {
  // If xVal is an integer that exists in data, return that
  const exact = data.find((d) => d.x === xVal);
  if (exact) return exact.y;

  // Otherwise find the data points that bracket xVal
  for (let i = 0; i < data.length - 1; i++) {
    const p1 = data[i];
    const p2 = data[i + 1];
    if (p1.x <= xVal && p2.x >= xVal) {
      const t = (xVal - p1.x) / (p2.x - p1.x);
      return p1.y + t * (p2.y - p1.y);
    }
  }
  // fallback (if xVal is outside domain)
  return 0;
};
