import { FC } from "react";
import { Updater } from "use-immer";

import { Input } from "~/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { OnboardingSettings } from "~/routes/suivi/OnboardingPage.tsx";
import { SUIVI_SELECTIONS } from "~/routes/suivi/utils/selections.ts";

interface OnboardingSelectionProps {
  settings: OnboardingSettings;
  onChangeSettings: Updater<OnboardingSettings>;
}

export const OnboardingSelection: FC<OnboardingSelectionProps> = ({
  settings,
  onChangeSettings,
}) => (
  <div>
    <p className="text-sm text-slate-600">Quelle sélection préparez-vous ?</p>
    <Select
      value={settings.selection.name ?? ""}
      onValueChange={(selectionName) =>
        onChangeSettings({
          ...settings,
          selection: { ...settings.selection, name: selectionName },
        })
      }
    >
      <SelectTrigger className="w-full mt-2">
        <SelectValue placeholder="Choisir…" />
      </SelectTrigger>
      <SelectContent>
        {Object.keys(SUIVI_SELECTIONS).map((selectionKey) => (
          <SelectItem key={`selection-${selectionKey}`} value={selectionKey}>
            {SUIVI_SELECTIONS[selectionKey]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
    {settings.selection.name === "other" && (
      <Input
        className="mt-2.5"
        placeholder="Saisissez la sélection que vous préparez…"
        value={settings.selection.customName ?? ""}
        onChange={(evt) =>
          onChangeSettings({
            ...settings,
            selection: {
              ...settings.selection,
              customName: evt.currentTarget.value,
            },
          })
        }
        autoFocus={true}
      />
    )}
  </div>
);
