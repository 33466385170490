import { parse } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { clamp } from "lodash";

import { EplAttempt } from "~/routes/suivi/types/EplAttempt.ts";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import {
  PilotestAttempt,
  PilotestAttemptApiJson,
} from "~/routes/suivi/types/PilotestAttempt.ts";

export const sanitizePilotestAttemptCsv = (
  rawAttempt: PilotestAttempt,
): ExerciseAttempt => ({
  provider: "pilotest",
  name: rawAttempt.test.replace("\t", "") + "##PT",
  percentScore: parseInt(rawAttempt.score.replace("%", "")),
  stanineClass: parseInt(rawAttempt.stanine),
  at: parse(rawAttempt.at + "Z", "EEEE dd LLLL yyyy H'h'mX", new Date(), {
    // @ts-expect-error Working as expected…
    locale: frLocale,
  }),
});

export const sanitizePilotestAttemptApi = (
  rawAttempt: PilotestAttemptApiJson,
): ExerciseAttempt => ({
  provider: "pilotest",
  name: rawAttempt.name + "##PT",
  percentScore: rawAttempt.percent_score,
  stanineClass: rawAttempt.stanine_class,
  at: new Date(rawAttempt.at),
});

export const sanitizeEplAttemptApi = (
  rawAttempts: EplAttempt,
): ExerciseAttempt => ({
  provider: "eplTest",
  name:
    rawAttempts.test +
    (rawAttempts.variant ? " (" + rawAttempts.variant + ")" : "") +
    "##EPL",
  percentScore: Math.round(rawAttempts.score_percent * 100),
  stanineClass: clamp(Math.round(rawAttempts.stanine_class), 1, 9),
  at: new Date(rawAttempts.date),
});
