import { FC, useCallback, useEffect, useState } from "react";

import { Button } from "~/components/ui/button";
import { AdminQuestion } from "~/routes/quizlet/AdminQuestion.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface AdminProps {}

const QUIZLET_ADMIN_PAGINATION = 20;

export const Admin: FC<AdminProps> = () => {
  const [page, setPage] = useState(0);

  const [questions, setQuestions] = useState<
    Tables<"quizlet_questions">[] | null
  >(null);

  const refreshQuestions = useCallback(
    () =>
      supabase
        .from("quizlet_questions")
        .select()
        .range(page, page + QUIZLET_ADMIN_PAGINATION - 1)
        .order("created_at", { ascending: false })
        .then(({ data }) => {
          setQuestions(data ?? []);
        }),
    [page],
  );

  useEffect(() => {
    refreshQuestions();
  }, [refreshQuestions]);

  if (questions === null) {
    return "Chargement…";
  }

  return (
    <section className="flex flex-col gap-4">
      <div className="flex gap-8 px-4">
        <div className="w-2/12 uppercase text-slate-500 tracking-wider text-sm">
          Catégorie
        </div>
        <div className="w-5/12 uppercase text-slate-500 tracking-wider text-sm">
          Question
        </div>
        <div className="w-5/12 uppercase text-slate-500 tracking-wider text-sm">
          Réponse
        </div>
      </div>
      {questions.map((question) => (
        <AdminQuestion
          key={`question-${question.id}`}
          question={question}
          onRefreshQuestion={refreshQuestions}
        />
      ))}
      <div className="text-right space-x-3">
        <Button
          variant="outline"
          onClick={() => setPage((prevPage) => prevPage - 1)}
          disabled={page === 0}
        >
          Précédent
        </Button>
        <Button
          variant="outline"
          onClick={() => setPage((prevPage) => prevPage + 1)}
        >
          Suivant
        </Button>
      </div>
    </section>
  );
};
