import clsx from "clsx";
import { FC } from "react";
import {
  RiProgress2Line,
  RiProgress4Line,
  RiProgress6Line,
  RiProgress7Line,
  RiProgress8Line,
} from "react-icons/ri";
import { TbProgressAlert, TbProgressHelp } from "react-icons/tb";

interface ProgressStatusProps {
  distance: number | null;
}

export const ProgressStatus: FC<ProgressStatusProps> = ({ distance }) => (
  <span className="block w-5 h-5">
    {distance === null ? (
      <TbProgressHelp className={clsx("w-full h-full text-slate-400")} />
    ) : distance < 3 ? (
      <RiProgress8Line className={clsx("w-full h-full text-green-400")} />
    ) : distance < 5 ? (
      <RiProgress7Line className={clsx("w-full h-full text-yellow-400")} />
    ) : distance < 7 ? (
      <RiProgress6Line className={clsx("w-full h-full text-orange-400")} />
    ) : distance < 12 ? (
      <RiProgress4Line className={clsx("w-full h-full text-rose-400")} />
    ) : distance < 15 ? (
      <RiProgress2Line className={clsx("w-full h-full text-red-400")} />
    ) : (
      <TbProgressAlert className={clsx("w-full h-full text-red-600")} />
    )}
  </span>
);
