import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { Chart } from "~/routes/suivi/components/Chart.tsx";
import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useAggregatedResultsFromDate } from "~/routes/suivi/hooks/useAggregatedResultsFromDate.ts";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

interface ExercisesResultsPopoverProps {
  exerciseName: string;
}

export const ExercisesResultsPopover: FC<ExercisesResultsPopoverProps> = ({
  exerciseName,
}) => {
  const { settings } = useSettings();
  const { renamingsList } = useRenamings();

  const allAggregatedResultsFromDate = useAggregatedResultsFromDate();
  const aggregatedResultsFromDate = allAggregatedResultsFromDate.filter((r) =>
    Object.prototype.hasOwnProperty.call(r, exerciseName),
  );

  if (aggregatedResultsFromDate.length === 0) {
    return null;
  }

  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger className="inline-flex items-center gap-2 cursor-default truncate">
        <ExerciseWithIcon
          exerciseName={exerciseName}
          customName={renamingsList[exerciseName]}
        />
      </TooltipTrigger>
      <TooltipContent className="w-[396px] pt-2.5 ml-4" side="right">
        <Chart
          display={settings?.table_display ?? "stanine"}
          exercise={exerciseName}
          results={aggregatedResultsFromDate}
          withAnimation={false}
          withLegend={false}
          size={240}
        />
        <p className="flex items-center justify-center leading-5 mb-1 gap-2">
          <span>Progression générale pour</span>
          <span className="inline-flex gap-1 items-center justify-center font-medium">
            <ExerciseWithIcon
              exerciseName={exerciseName}
              customName={renamingsList[exerciseName]}
              withLink={false}
            />
          </span>
        </p>
      </TooltipContent>
    </Tooltip>
  );
};
