import { FC } from "react";

import { ExercisesResultsHeader } from "~/routes/suivi/components/ExercisesResultsHeader.tsx";
import { ExercisesResultsHeaderEmpty } from "~/routes/suivi/components/ExercisesResultsHeaderEmpty.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { getFixedColumnSpan } from "~/routes/suivi/utils/getFixedColumnSpan.ts";

export const ExercisesResultsHeaders: FC = () => {
  const { settings } = useSettings();

  return (
    <div className="grid grid-cols-2 sm:grid-cols-12 gap-4 px-2">
      <div
        className={`sm:col-span-${getFixedColumnSpan(settings?.hidden_elements ?? [])}`}
      />
      {!settings?.hidden_elements?.includes("column_general") && (
        <ExercisesResultsHeader title="Début" />
      )}
      {!settings?.hidden_elements?.includes("column_7_days") && (
        <ExercisesResultsHeader title="7 jours" />
      )}
      {!settings?.hidden_elements?.includes("column_3_days") && (
        <ExercisesResultsHeader title="3 jours" />
      )}
      {!settings?.hidden_elements?.includes("column_today") && (
        <ExercisesResultsHeader title="Aujourd'hui" />
      )}
      {!settings?.hidden_elements?.includes("column_last_attempt") && (
        <ExercisesResultsHeaderEmpty />
      )}
    </div>
  );
};
