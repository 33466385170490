import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { MoveIcon } from "lucide-react";
import { FC } from "react";

import { ExercisesResultsFlagExercisePopover } from "~/routes/suivi/components/ExercisesResultsFlagExercisePopover.tsx";
import { ExercisesResultsLastAttempt } from "~/routes/suivi/components/ExercisesResultsLastAttempt.tsx";
import { ExercisesResultsPopover } from "~/routes/suivi/components/ExercisesResultsPopover.tsx";
import { ExercisesResultsRenameExercise } from "~/routes/suivi/components/ExercisesResultsRenameExercise.tsx";
import { ExercisesResultsResults } from "~/routes/suivi/components/ExercisesResultsResults.tsx";
import { ExercisesResultsStatus } from "~/routes/suivi/components/ExercisesResultsStatus.tsx";
import { useFlaggedExercises } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";
import { getFixedColumnSpan } from "~/routes/suivi/utils/getFixedColumnSpan.ts";

export interface ExercisesResultsRowsRowMainProps {
  exercise: ExerciseResults;
  draggableProps: DraggableProvidedDragHandleProps | null;
  group?: string | null;
}

export const ExercisesResultsRowsRowMain: FC<
  ExercisesResultsRowsRowMainProps
> = ({ exercise, group, draggableProps }) => {
  const { settings } = useSettings();
  const { flaggedExercisesList } = useFlaggedExercises();

  return (
    <div
      className="grid grid-cols-2 sm:grid-cols-12 gap-4 px-2 py-1.5"
      style={
        flaggedExercisesList[exercise.name]
          ? {
              backgroundColor: flaggedExercisesList[exercise.name].color ?? "",
            }
          : {}
      }
    >
      <div
        className={`sm:col-span-${getFixedColumnSpan(settings?.hidden_elements ?? [])} h-6 flex items-center gap-2.5 text-sm sm:pl-2`}
      >
        {!settings?.hidden_elements?.includes("data_distance") && (
          <ExercisesResultsStatus distance={exercise.results.all.distance} />
        )}
        <ExercisesResultsPopover exerciseName={exercise.name} />
        <div />
        <ExercisesResultsRenameExercise exerciseName={exercise.name} />
        <ExercisesResultsFlagExercisePopover exerciseName={exercise.name} />
        {Boolean(group) && (
          <div
            {...draggableProps}
            className="hidden sm:group-hover:block cursor-move"
          >
            <MoveIcon className="w-4 h-4 text-slate-400 hover:text-slate-500" />
          </div>
        )}
      </div>
      {!settings?.hidden_elements?.includes("column_general") && (
        <ExercisesResultsResults
          name={exercise.name}
          result={exercise.results.all}
          display={settings?.table_display ?? "stanine"}
        />
      )}
      {!settings?.hidden_elements?.includes("column_7_days") && (
        <ExercisesResultsResults
          name={exercise.name}
          result={exercise.results.last7Days}
          display={settings?.table_display ?? "stanine"}
        />
      )}
      {!settings?.hidden_elements?.includes("column_3_days") && (
        <ExercisesResultsResults
          name={exercise.name}
          result={exercise.results.last3Days}
          display={settings?.table_display ?? "stanine"}
        />
      )}
      {!settings?.hidden_elements?.includes("column_today") && (
        <ExercisesResultsResults
          name={exercise.name}
          result={exercise.results.last0Days}
          display={settings?.table_display ?? "stanine"}
        />
      )}
      {!settings?.hidden_elements?.includes("column_last_attempt") && (
        <ExercisesResultsLastAttempt
          name={exercise.name}
          lastAttempt={exercise.lastAttempt}
          display={settings?.table_display ?? "stanine"}
        />
      )}
    </div>
  );
};
