import { clsx } from "clsx";
import { FC } from "react";

import { formatNumber } from "~/utils/formatNumber.ts";

interface AdminHeaderPanelProps {
  title: string;
  value: number | string | null;
  className?: string;
}

export const AdminHeaderPanel: FC<AdminHeaderPanelProps> = ({
  title,
  value,
  className,
}) => {
  return (
    <div className={clsx("bg-slate-50 rounded-md px-3 py-2 border", className)}>
      <p className="uppercase text-slate-400 text-xs tracking-wider">{title}</p>
      <p className="text-right text-2xl font-mono mt-1">
        {value === null
          ? "–"
          : typeof value === "number"
            ? formatNumber(value)
            : value}
      </p>
    </div>
  );
};
