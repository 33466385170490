import { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { AttemptsProvider } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { FlaggedExercisesProvider } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { GroupsProvider } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { ImportsProvider } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { PilotestCredentialsProvider } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { RenamingsProvider } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { SettingsProvider } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { StaninesProvider } from "~/routes/suivi/providers/StaninesProvider.tsx";

export const Suivi: FC = () => (
  <SettingsProvider>
    <ImportsProvider>
      <PilotestCredentialsProvider>
        <AttemptsProvider>
          <StaninesProvider>
            <GroupsProvider>
              <FlaggedExercisesProvider>
                <RenamingsProvider>
                  <Suspense>
                    <Outlet />
                  </Suspense>
                </RenamingsProvider>
              </FlaggedExercisesProvider>
            </GroupsProvider>
          </StaninesProvider>
        </AttemptsProvider>
      </PilotestCredentialsProvider>
    </ImportsProvider>
  </SettingsProvider>
);
