import { format } from "date-fns";

type FormatDateFormat = "dateTime" | "dateTimeLong" | "date" | "dateLong";

export const formatDate = (
  date: Date | string,
  dateFormat: FormatDateFormat = "dateTime",
) => {
  const dateDate = typeof date === "string" ? new Date(date) : date;

  const formatFromFormat =
    dateFormat === "dateTimeLong"
      ? "dd/MM/yy HH:mm:ss"
      : dateFormat === "date"
        ? "dd/MM"
        : dateFormat === "dateLong"
          ? "dd/MM/yy"
          : "dd/MM/yy HH:mm";

  return format(new Date(dateDate), formatFromFormat);
};
