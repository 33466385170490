import { SettingsIcon, SquarePlusIcon } from "lucide-react";
import { FC, useCallback, useEffect, useState } from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";
import { AddQuestionModal } from "~/routes/quizlet/AddQuestionModal.tsx";
import { Admin } from "~/routes/quizlet/Admin.tsx";
import { Password } from "~/routes/quizlet/Password.tsx";
import { Questions, QUIZLET_CATEGORIES } from "~/routes/quizlet/Questions.tsx";
import { supabase } from "~/supabase.ts";
import { Enums, Tables } from "~/supabase.types.ts";

export type QuizletFilter = "allQuestions" | "bookmarked" | "mine";

interface QuizletWithSettingsProps {
  settings: Tables<"quizlet_settings"> | null;
  refreshSettings: () => void;
}

const QuizletWithSettings: FC<QuizletWithSettingsProps> = ({
  settings,
  refreshSettings,
}) => {
  const { user, canAccess } = useCurrentUser();

  const [topicCounts, setTopicCounts] = useState<
    Tables<"views_quizlet_categories">[] | []
  >([]);

  const [filter, setFilter] = useState<QuizletFilter>("allQuestions");
  const [topic, setTopic] = useState<Enums<"quizlet_category"> | "">("");

  const [adminMode, setAdminMode] = useState(false);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);

  useEffect(() => {
    supabase
      .from("views_quizlet_categories")
      .select()
      .then(({ data }) => setTopicCounts(data ?? []));
  }, []);

  const handleOnUnlock = async () => {
    supabase
      .from("quizlet_settings")
      .insert({ user_id: user.id, unlocked_at: new Date().toISOString() })
      .then(refreshSettings);
  };

  const canViewQuestions = settings && !settings.revoked_at;

  return (
    <Wrapper
      extended={adminMode}
      header={
        <>
          {canViewQuestions && (
            <div className="flex flex-wrap sm:flex-nowrap items-center justify-center gap-2 sm:gap-3">
              {canAccess(Role.AdminQuizlet) && (
                <Button
                  variant="ghost-destructive"
                  onClick={() => setAdminMode(!adminMode)}
                  className="hidden sm:flex"
                >
                  <SettingsIcon />
                  <span className="ml-2">Admin</span>
                </Button>
              )}
              <ScoreButton
                exercise={Exercise.Quizlet}
                variant={`${filter}:${topic === "" ? "alltopics" : topic}`}
                label={`Culture G (${topic === "" ? "Tous sujets" : QUIZLET_CATEGORIES[topic]})`}
                withTimings={false}
                filter={() => true}
              />
              {user.discord_username !== null && (
                <Button
                  variant="ghost"
                  onClick={() => setShowAddQuestionModal(true)}
                  className="hidden sm:flex"
                >
                  <SquarePlusIcon className="w-5 h-5" />
                  <span className="ml-2">Ajouter une question</span>
                </Button>
              )}
              <Select
                value={filter}
                onValueChange={(filter) => setFilter(filter as QuizletFilter)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner une catégorie…" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="allQuestions">
                    Toutes les questions
                  </SelectItem>
                  <SelectItem value="bookmarked">Mes favoris</SelectItem>
                  {user.discord_username && (
                    <SelectItem value="mine">Mes questions</SelectItem>
                  )}
                </SelectContent>
              </Select>
              <Select
                value={topic}
                onValueChange={(topic) =>
                  setTopic(topic as Enums<"quizlet_category">)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Tous les sujets" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(QUIZLET_CATEGORIES).map(
                    ([categoryKey, categoryValue]) => (
                      <SelectItem
                        key={`topic-${categoryKey}`}
                        value={categoryKey}
                      >
                        {categoryValue}{" "}
                        <span className="ml-1 text-[11px] text-slate-500 tabular-nums">
                          {
                            topicCounts.find(
                              (topic) => topic.category === categoryKey,
                            )?.count
                          }{" "}
                          questions
                        </span>
                      </SelectItem>
                    ),
                  )}
                </SelectContent>
              </Select>
            </div>
          )}
        </>
      }
    >
      {canAccess(Role.AdminQuizlet) && adminMode ? (
        <Admin />
      ) : canViewQuestions ? (
        <Questions filter={filter} topic={topic} />
      ) : (
        <Password onUnlock={handleOnUnlock} />
      )}
      {user.discord_username !== null && (
        <AddQuestionModal
          open={showAddQuestionModal}
          onDismiss={() => setShowAddQuestionModal(false)}
        />
      )}
    </Wrapper>
  );
};

export const Quizlet = () => {
  const { user } = useCurrentUser();

  const [quizletSettings, setQuizletSettings] = useState<
    Tables<"quizlet_settings"> | undefined | null
  >(undefined);

  const getSettings = useCallback(() => {
    supabase
      .from("quizlet_settings")
      .select()
      .eq("user_id", user.id)
      .limit(1)
      .maybeSingle()
      .then(({ data }) => {
        setQuizletSettings(data);
      });
  }, [user.id]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  if (quizletSettings === undefined) {
    return <LayoutLoading />;
  }

  return (
    <QuizletWithSettings
      settings={quizletSettings}
      refreshSettings={getSettings}
    />
  );
};
