import clsx from "clsx";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { FC } from "react";
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from "recharts";

import { ExercisesResultsKdToolsStanine } from "~/routes/suivi/components/ExercisesResultsKdToolsStanine.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useFlaggedExercises } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";
import { backgroundClassNameFromStanineClass } from "~/routes/suivi/utils/backgroundClassNameFromStanineClass.ts";
import { fillClassNameFromStanineClass } from "~/routes/suivi/utils/fillClassNameFromStanineClass.ts";
import { formatDate } from "~/utils/formatDate.ts";

export interface ExercisesResultsRowsRowExpandedProps {
  exercise: ExerciseResults;
}

export const ExercisesResultsRowsRowExpanded: FC<
  ExercisesResultsRowsRowExpandedProps
> = ({ exercise }) => {
  const { settings } = useSettings();
  const { attempts: allAttempts } = useAttempts();
  const { flaggedExercisesList } = useFlaggedExercises();

  const attempts = orderBy(
    allAttempts.filter((a) => a.name === exercise.name),
    ["at"],
    ["asc"],
  );

  return (
    <div
      className="px-3 py-3 h-80 -mt-3"
      style={
        flaggedExercisesList[exercise.name]
          ? {
              backgroundColor: flaggedExercisesList[exercise.name].color ?? "",
            }
          : {}
      }
    >
      <div className="h-full rounded border border-slate-200 px-4 py-3 grid  grid-cols-1 sm:grid-cols-2 gap-3 bg-white">
        <div>
          <h2 className="font-medium text-xs text-slate-400 uppercase mb-2">
            Progression{" "}
            {settings?.display_from_date && (
              <span>
                depuis le {formatDate(settings?.display_from_date, "dateLong")}
              </span>
            )}
          </h2>
          <ResponsiveContainer width="100%" height={252}>
            <ScatterChart margin={{ top: 8, right: 16, bottom: 8, left: -42 }}>
              <CartesianGrid stroke="#e5e5e5" vertical={false} />
              <XAxis
                dataKey="at"
                tickFormatter={(time) => format(time, "d MMM yy")}
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fill: "#888" }}
                dy={5}
              />
              <YAxis dataKey="stanineClass" domain={[1, 9]} />
              <YAxis
                width={18}
                ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                domain={[1, 9]}
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 14, fill: "#888" }}
                dx={-5}
              />
              <Scatter name="Scores" data={attempts} fill="black">
                {attempts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    className={fillClassNameFromStanineClass(
                      entry.stanineClass,
                    )}
                  />
                ))}
              </Scatter>
            </ScatterChart>
          </ResponsiveContainer>
        </div>
        <div className="overflow-y-scroll overflow-x-hidden">
          <h2 className="font-medium text-xs text-slate-400 uppercase mb-2">
            Tous les résultats – {attempts?.length} essais
          </h2>
          <div className="mt-1 mb-6 flow-root">
            <table className="min-w-full">
              <tbody className="bg-white">
                {attempts
                  ?.slice()
                  .reverse()
                  .map((attempt, idx) => (
                    <tr key={idx} className="odd:bg-slate-50">
                      <td className="whitespace-nowrap py-1.5 pl-2 text-xs text-slate-500">
                        {format(attempt.at, "eeee d MMM y à HH:mm:ss")}
                      </td>
                      <td className="whitespace-nowrap px-2 py-1 text-xs text-slate-900 tabular-nums flex justify-end items-center gap-4">
                        <p>{attempt.percentScore} %</p>
                        <p className="flex items-center gap-2">
                          <span
                            className={clsx(
                              "px-4 py-1 rounded",
                              backgroundClassNameFromStanineClass(
                                attempt.stanineClass,
                              ),
                            )}
                          >
                            {attempt.stanineClass}
                          </span>
                          <ExercisesResultsKdToolsStanine
                            name={attempt.name}
                            percentage={attempt.percentScore}
                          />
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
