import clsx from "clsx";
import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { ExercisesResultsKdToolsStanine } from "~/routes/suivi/components/ExercisesResultsKdToolsStanine.tsx";
import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";
import { borderClassNameFromStanineClass } from "~/routes/suivi/utils/borderClassNameFromStanineClass.ts";
import { deserializeExerciseName } from "~/routes/suivi/utils/deserializeExerciseName.ts";
import { textClassNameFromPercentScore } from "~/routes/suivi/utils/textClassNameFromPercentScore.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";
import { Enums } from "~/supabase.types.ts";

interface ExercisesResultsLastAttemptProps {
  name: string;
  lastAttempt: ExerciseResults["lastAttempt"];
  display?: Enums<"graph_display">;
  className?: string;
}

export const ExercisesResultsLastAttempt: FC<
  ExercisesResultsLastAttemptProps
> = ({ name, lastAttempt, display, className }) => {
  const { provider } = deserializeExerciseName(name);

  return (
    <div className={clsx(className, "h-6 hidden last-of-type:block sm:block")}>
      {lastAttempt &&
        (display?.includes("stanine") ? (
          <div className="flex items-center justify-center gap-2">
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <p
                  className={clsx(
                    "flex items-center justify-center shrink-0 w-8 h-6 text-sm sm:text-xs text-center rounded tabular-nums bg-white border cursor-default",
                    textClassNameFromStanineClass(lastAttempt.stanineClass),
                    borderClassNameFromStanineClass(lastAttempt.stanineClass),
                  )}
                >
                  <span className="font-medium">
                    {lastAttempt.stanineClass}
                  </span>
                </p>
              </TooltipTrigger>
              <TooltipContent>
                Dernière classe{" "}
                {provider === "eplTest" ? "EPLtest" : "Pilotest"} obtenue (score
                de {lastAttempt.percentScore} %)
              </TooltipContent>
            </Tooltip>

            <ExercisesResultsKdToolsStanine
              name={name}
              percentage={lastAttempt.percentScore}
            />
          </div>
        ) : (
          <p className="h-6 flex items-center justify-end tabular-nums text-xs">
            <span
              className={textClassNameFromPercentScore(
                lastAttempt.percentScore,
              )}
            >
              {lastAttempt.percentScore} %
            </span>
          </p>
        ))}
    </div>
  );
};
