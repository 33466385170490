import { differenceInDays } from "date-fns";
import { HeartHandshakeIcon } from "lucide-react";
import { FC, ReactNode } from "react";

import { LinkDonate } from "~/components/LinkDonate.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { useDonations } from "~/providers/DonationsProvider.tsx";

interface BannerSupportProps {
  shouldHide?: boolean;
  children: ReactNode;
}

export const BannerSupport: FC<BannerSupportProps> = ({
  shouldHide,
  children,
}) => {
  const { user } = useCurrentUser();
  const { donations } = useDonations();

  if (
    shouldHide ||
    differenceInDays(new Date(), new Date(user.created_at)) <= 7 ||
    donations.length > 0
  ) {
    return null;
  }

  return (
    <div className="flex flex-col border-2 border-blue-600 rounded px-5 py-4 mb-4 sm:mb-10 sm:shadow-md bg-blue-50">
      <p className="text-xl font-semibold flex justify-between text-blue-600 relative">
        <span>Soutenir KD Tools</span>
        <HeartHandshakeIcon className="w-10 h-10 absolute right-0" />
      </p>
      <p className="mt-2">{children}</p>
      <p className="mt-1">
        Si vous le pouvez, n'hésitez pas à{" "}
        <LinkDonate wrapperClassname="font-semibold text-blue-600">
          faire un don
        </LinkDonate>{" "}
        pour participer aux frais d'hébergement.
      </p>
      <p className="text-sm mt-2">
        <LinkDonate wrapperClassname="text-slate-700">
          Faire un don et ne plus afficher
        </LinkDonate>
      </p>
    </div>
  );
};
