export const Logo = ({ className = "" }) => (
  <svg
    version="1.1"
    viewBox="0 0 700 700"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="m105.57 162.94c-6.9727-14.902 5.0586-31.664 21.383-29.805l76.398 8.6953c30.215 3.4453 57.477 19.824 74.676 44.898l174.61 254.27h149.48c9.8984 0 19.195-4.6484 25.129-12.496l0.95703-1.3398c3.5547-5.25 2.9531-12.305-1.4492-16.871l-15.586-16.27c-18.293-19.086-43.613-29.887-70.055-29.887h-98.762c-11.594 0-21-9.4062-21-21s9.4062-21 21-21h98.762c37.898 0 74.156 15.477 100.38 42.82l15.586 16.27c18.102 18.867 20.562 47.824 5.9062 69.48-13.672 20.18-36.477 32.293-60.867 32.293h-160.54c-6.918 0-13.398-3.418-17.309-9.1055l-180.88-263.4c-10.336-15.039-26.66-24.855-44.789-26.934l-39.047-4.4297 75.852 161.41c6.3711 13.562-3.0898 29.094-17.801 29.887l-1.2031 0.027344h-48.535c-6.9727 0-13.945-0.79297-20.727-2.3789l-44.926-10.527 34.617 61.934c7.1367 12.77 20.371 20.891 34.891 21.492l1.75 0.027344h165.98c11.594 0 21 9.4062 21 21s-9.4062 21-21 21h-165.98c-30.406 0-58.461-16.461-73.312-43.012l-57.832-103.47c-8.8867-15.914 5.3594-34.836 23.105-30.68l91.273 21.328c3.6367 0.84766 7.3828 1.2852 11.156 1.2852h15.449z" />
  </svg>
);
