import clsx from "clsx";
import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { ExercisesResultsKdToolsStanine } from "~/routes/suivi/components/ExercisesResultsKdToolsStanine.tsx";
import { ExercisesResultsMinMaxAvg } from "~/routes/suivi/components/ExercisesResultsMinMaxAvg.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseResult } from "~/routes/suivi/types/ExerciseResult.ts";
import { textClassNameFromPercentScore } from "~/routes/suivi/utils/textClassNameFromPercentScore.ts";
import { Enums } from "~/supabase.types.ts";

interface ExercisesResultsResultsProps {
  name: string;
  result: ExerciseResult;
  display?: Enums<"graph_display">;
  className?: string;
}

export const ExercisesResultsResults: FC<ExercisesResultsResultsProps> = ({
  name,
  result,
  display,
  className,
}) => {
  const { settings } = useSettings();

  return (
    <div
      className={clsx(
        className,
        "sm:col-span-2 h-6 hidden last-of-type:block sm:block border-r px-2",
      )}
    >
      {result.count > 0 &&
        (display?.includes("stanine") ? (
          <div className="flex items-center justify-end gap-2">
            {!settings?.hidden_elements?.includes("data_attempts_count") && (
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <p className="font-mono text-xs text-slate-400 tabular-nums cursor-default">
                    {result.count}
                  </p>
                </TooltipTrigger>
                <TooltipContent>Nombre d'essais</TooltipContent>
              </Tooltip>
            )}
            <ExercisesResultsMinMaxAvg
              exercise={name}
              min={result.min}
              max={result.max}
              avg={result.averageStanineClass}
            />
            <ExercisesResultsKdToolsStanine
              name={name}
              percentage={result.averagePercentScore}
            />
          </div>
        ) : (
          <p className="h-6 flex items-center justify-between tabular-nums text-sm">
            <span className="text-xs text-slate-400" title="Tentatives">
              {result.count}
            </span>
            <span
              className={textClassNameFromPercentScore(
                result.averagePercentScore,
              )}
            >
              {result.averagePercentScore} %
            </span>
          </p>
        ))}
    </div>
  );
};
