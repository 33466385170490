import { setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "~/index.css";
import router from "~/router.tsx";

setDefaultOptions({ locale: fr });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />,
);
