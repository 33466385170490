import { format, parse } from "date-fns";
import { FC } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import SafeColor from "safecolor";
import colors from "tailwindcss/colors";

import { ChartsCustomTooltip } from "~/routes/suivi/components/ChartsCustomTooltip.tsx";
import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useFlaggedExercises } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { getExerciseNameAndSource } from "~/routes/suivi/utils/getExerciseNameAndSource.ts";
import { AggregatedResultByDate } from "~/routes/suivi/utils/resultsByDate.ts";
import { Enums } from "~/supabase.types.ts";

interface ChartProps {
  display: Enums<"graph_display">;
  exercise: string;
  results: AggregatedResultByDate[];
  withAnimation?: boolean;
  withLegend?: boolean;
  size?: number;
}

export const Chart: FC<ChartProps> = ({
  display,
  exercise,
  results,
  withAnimation = true,
  withLegend = true,
  size = 196,
}) => {
  const { flaggedExercisesList } = useFlaggedExercises();
  const { renamingsList } = useRenamings();

  const { name } = getExerciseNameAndSource(exercise);

  return (
    <div key={`exercise-${exercise}`}>
      <div className="w-full z-10" style={{ height: `${size}px` }}>
        <ResponsiveContainer>
          <LineChart data={results}>
            <CartesianGrid stroke="#e5e5e5" vertical={false} />
            <XAxis
              dataKey="date"
              tickFormatter={(value) =>
                format(parse(value, "yy-MM-dd", new Date()), "d MMM")
              }
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: "#888" }}
              dy={5}
            />
            <YAxis
              width={display === "stanine" ? 18 : 32}
              ticks={
                display === "stanine"
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9]
                  : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
              }
              domain={display === "stanine" ? [1, 9] : [0, 100]}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: "#888" }}
              dx={-5}
              interval={0}
            />
            <Tooltip
              content={<ChartsCustomTooltip view={display} />}
              itemStyle={{ fontSize: 12 }}
            />
            {display === "stanine" && (
              <>
                <ReferenceArea y1={1} y2={3} fill={colors.red[100]} />
                <ReferenceArea y1={3} y2={5} fill={colors.amber[100]} />
                <ReferenceArea y1={5} y2={7} fill={colors.green[100]} />
                <ReferenceArea y1={7} y2={9} fill={colors.blue[100]} />
              </>
            )}
            <Line
              type="bump"
              key={exercise}
              dataKey={`${exercise}.${display}`}
              stroke={new SafeColor().random(name)}
              strokeWidth={2}
              dot={false}
              connectNulls={true}
              isAnimationActive={withAnimation}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {withLegend && (
        <p
          className="flex justify-center items-center space-x-2 text-sm rounded"
          style={{
            backgroundColor: flaggedExercisesList[exercise]?.color ?? "",
          }}
        >
          <ExerciseWithIcon
            exerciseName={exercise}
            customName={renamingsList[exercise]}
            withLink={false}
          />
        </p>
      )}
    </div>
  );
};
