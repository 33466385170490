import { FC } from "react";

import { ProgressStatus } from "~/components/ProgressStatus.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { formatDate } from "~/utils/formatDate.ts";

interface ExercisesResultsStatusProps {
  distance: number | undefined;
}

export const ExercisesResultsStatus: FC<ExercisesResultsStatusProps> = ({
  distance,
}) => {
  const { settings } = useSettings();

  return (
    <p className="hidden sm:flex items-center justify-end mr-1">
      <Tooltip delayDuration={0}>
        <TooltipTrigger className="cursor-default">
          <ProgressStatus distance={distance === undefined ? null : distance} />
        </TooltipTrigger>
        <TooltipContent>
          {distance !== undefined ? (
            <>
              {distance} {distance > 1 ? "jours" : "jour"} depuis le dernier
              essai
            </>
          ) : (
            `Aucun essai trouvé ${settings?.display_from_date && `depuis le ${formatDate(settings.display_from_date, "dateLong")}`}`
          )}
        </TooltipContent>
      </Tooltip>
    </p>
  );
};
