import Papa from "papaparse";
import { ChangeEvent } from "react";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { PilotestAttempt } from "~/routes/suivi/types/PilotestAttempt.ts";
import { sanitizePilotestAttemptCsv } from "~/routes/suivi/utils.ts";

export const handleCsvUpload = (
  event: ChangeEvent<HTMLInputElement>,
  onResults: (results?: ExerciseAttempt[]) => void,
) => {
  if (!event.target?.files?.[0]) {
    return;
  }

  Papa.parse(event.target.files[0], {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      if (results.errors.length > 0) {
        return;
      }

      onResults(
        (results.data as PilotestAttempt[]).map(sanitizePilotestAttemptCsv),
      );
    },
  });
};
