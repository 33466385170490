import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useSet } from "react-use";

import { ExercisesResultsRowsRowExpanded } from "~/routes/suivi/components/ExercisesResultsRowsRowExpanded.tsx";
import { ExercisesResultsRowsRowMain } from "~/routes/suivi/components/ExercisesResultsRowsRowMain.tsx";
import { useGroups } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";
import { supabase } from "~/supabase.ts";

interface ExercisesResultsRowsProps {
  exercises: ExerciseResults[];
  group?: string | null;
}

export const ExercisesResultsRows: FC<ExercisesResultsRowsProps> = ({
  exercises = [],
  group,
}) => {
  const { refreshGroups } = useGroups();

  const [items, setItems] = useState(exercises);

  const [expandedItems, { toggle: expandItem }] = useSet(new Set<string>([]));

  useEffect(() => {
    setItems(exercises);
  }, [exercises]);

  const handleOnDragEnd = (result: DropResult) => {
    if (!group || !result.destination) {
      return;
    }

    const updatedItems = Array.from(items);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    supabase
      .from("suivi_groups")
      .update({
        exercises: updatedItems.map((i) => i.name),
        use_custom_order: true,
      })
      .eq("id", group)
      .then(refreshGroups);

    setItems(updatedItems);
  };

  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex flex-col"
            >
              {items.map((exercise, index) => (
                <Draggable
                  key={exercise.name}
                  index={index}
                  draggableId={exercise.name}
                  isDragDisabled={!group}
                >
                  {(provided, snapshot) => (
                    <div
                      key={exercise.name}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      onClick={() => expandItem(exercise.name)}
                      className={clsx(
                        "group cursor-pointer",
                        snapshot.isDragging
                          ? "bg-sky-200 rounded border border-sky-300"
                          : index % 2 === 0
                            ? "bg-slate-50 hover:bg-sky-50"
                            : "hover:bg-sky-50",
                      )}
                    >
                      <ExercisesResultsRowsRowMain
                        group={group}
                        exercise={exercise}
                        draggableProps={provided.dragHandleProps}
                      />
                      {expandedItems.has(exercise.name) && (
                        <ExercisesResultsRowsRowExpanded exercise={exercise} />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ExercisesResultsRows;
