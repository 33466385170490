import { BannerSupport } from "~/components/BannerSupport.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useImports } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { formatNumber } from "~/utils/formatNumber.ts";

export const BannerSupportSuivi = () => {
  const { imports } = useImports();
  const { attempts } = useAttempts();
  const { settings } = useSettings();

  return (
    <BannerSupport shouldHide={!settings || imports.length <= 5}>
      Depuis votre inscription, vous avez importé{" "}
      <span className="font-semibold">
        {formatNumber(attempts.length)} résultats Pilotest et/ou EPLtest
      </span>{" "}
      pour suivre votre progression sur KD Tools !
    </BannerSupport>
  );
};
