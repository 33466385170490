import { LibraryBigIcon, SettingsIcon } from "lucide-react";
import { FC, useEffect, useMemo } from "react";
import { useSet } from "react-use";

import { ButtonHide } from "~/components/ButtonHide.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu.tsx";
import { ExercisesResultsHeaders } from "~/routes/suivi/components/ExercisesResultsHeaders.tsx";
import ExercisesResultsRows from "~/routes/suivi/components/ExercisesResultsRows.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { getExerciseResultsFromExerciseAttempts } from "~/routes/suivi/utils/getExerciseResultsFromExerciseAttempts.ts";
import { sortExerciseResults } from "~/routes/suivi/utils/sortExerciseResults.ts";
import { Enums } from "~/supabase.types.ts";
import { formatNumber } from "~/utils/formatNumber.ts";

export const ExercisesResults: FC = () => {
  const { attempts } = useAttempts();
  const { settings, saveSettings } = useSettings();

  const [hiddenElements, { has: isElementHidden, toggle: toggleElement }] =
    useSet<Enums<"suivi_table_element">>(new Set(settings?.hidden_elements));

  const exerciseResults = useMemo(
    () =>
      getExerciseResultsFromExerciseAttempts(
        attempts,
        settings?.display_from_date ?? "",
      ),
    [attempts, settings?.display_from_date],
  );

  useEffect(() => {
    saveSettings({ hidden_elements: [...hiddenElements] });
  }, [hiddenElements, saveSettings]);

  const exerciseRows = sortExerciseResults(
    exerciseResults,
    settings?.current_group?.exercises ?? [],
    settings?.current_group?.use_custom_order ?? false,
  );

  if (attempts.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="border rounded">
        <div className="flex items-center justify-between border-b px-3 py-1.5 bg-slate-100">
          <p className="font-medium text-2xl inline-flex items-center gap-2.5 pl-1">
            <LibraryBigIcon className="text-slate-600" />
            <span>Classes</span>
          </p>
          <div className="hidden sm:flex sm:items-center sm:gap-2">
            <ButtonHide
              hidden={settings?.table_hidden}
              onToggle={(newValue) => saveSettings({ table_hidden: newValue })}
            />
            {!settings?.table_hidden && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    <SettingsIcon className="w-4 h-4" />
                    <span className="ml-2">Paramètres</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>Colonnes</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("column_general")}
                    onCheckedChange={() => toggleElement("column_general")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Général
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("column_7_days")}
                    onCheckedChange={() => toggleElement("column_7_days")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    7 jours
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("column_3_days")}
                    onCheckedChange={() => toggleElement("column_3_days")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    3 jours
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("column_today")}
                    onCheckedChange={() => toggleElement("column_today")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Aujourd'hui
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("column_last_attempt")}
                    onCheckedChange={() => toggleElement("column_last_attempt")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Dernier résultat
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuLabel>Données</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("data_distance")}
                    onCheckedChange={() => toggleElement("data_distance")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Dernière session
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("data_attempts_count")}
                    onCheckedChange={() => toggleElement("data_attempts_count")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Nombre d'essais
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("data_stanine_min")}
                    onCheckedChange={() => toggleElement("data_stanine_min")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Classe plus faible
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("data_stanine_max")}
                    onCheckedChange={() => toggleElement("data_stanine_max")}
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Classe plus élevée
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={!isElementHidden("data_stanine_kd_tools")}
                    onCheckedChange={() =>
                      toggleElement("data_stanine_kd_tools")
                    }
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Classe KD Tools
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuLabel>Mesure</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuRadioGroup
                    value={settings?.table_display ?? "stanine"}
                    onValueChange={(display) =>
                      saveSettings({
                        table_display: display as Enums<"graph_display">,
                      })
                    }
                  >
                    <DropdownMenuRadioItem
                      value="stanine"
                      onSelect={(evt) => evt.preventDefault()}
                    >
                      Stanine (arrondi)
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem
                      value="stanine_with_decimals"
                      onSelect={(evt) => evt.preventDefault()}
                    >
                      Stanine (décimal)
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem
                      value="percentage"
                      onSelect={(evt) => evt.preventDefault()}
                    >
                      Pourcentage
                    </DropdownMenuRadioItem>
                  </DropdownMenuRadioGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
        {!settings?.table_hidden && (
          <div>
            <ExercisesResultsHeaders />
            <ExercisesResultsRows
              exercises={exerciseRows}
              group={settings?.current_group_id}
            />
          </div>
        )}
      </div>
      {!settings?.table_hidden && (
        <p className="mt-2 text-xs text-slate-400">
          <span className="font-medium">
            {new Intl.NumberFormat("fr-FR").format(attempts.length)} essais
            réalisés
          </span>{" "}
          (
          {formatNumber(
            attempts.filter((a) => a.provider === "pilotest").length,
          )}{" "}
          sur Pilotest et{" "}
          {formatNumber(
            attempts.filter((a) => a.provider === "eplTest").length,
          )}{" "}
          sur EPLtest).
        </p>
      )}
    </div>
  );
};
