import { startOfYear, subYears } from "date-fns";
import { FC, useEffect, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog.tsx";
import { Input } from "~/components/ui/input.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { formatDate } from "~/utils/formatDate.ts";

interface StanineKdToolsModalProps {
  open: boolean;
  onDismiss: () => void;
}

export const StanineKdToolsModal: FC<StanineKdToolsModalProps> = ({
  open,
  onDismiss,
}) => {
  const { settings, saveSettings } = useSettings();

  const [isSaving, setIsSaving] = useState(false);
  const [customDate, setCustomDate] = useState(
    settings?.stanine_from_date ?? "",
  );

  const handleOnSaveDate = () => {
    setIsSaving(true);

    saveSettings({
      stanine_from_date: customDate === "" ? null : customDate,
    }).then(onDismiss);
  };

  useEffect(() => {
    if (open) {
      setIsSaving(false);
      setCustomDate(settings?.stanine_from_date ?? "");
    }
  }, [open, settings?.stanine_from_date]);

  return (
    <Dialog open={open} onOpenChange={onDismiss}>
      <DialogContent
        className="max-w-2xl"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="inline-flex items-center gap-2">
            Classe KD Tools
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <p className="text-sm">
            Avec plus de 400 000 résultats envoyés jusqu'ici, KD Tools peut
            désormais{" "}
            <strong className="font-medium">
              calculer des classes stanine qui correspondent à une période
              donnée
            </strong>{" "}
            et vous permettre de vous situer par rapport aux autres candidats de
            la sélection que vous préparez.
          </p>
          <p className="text-sm">
            Par défaut, la période utilisée pour calculer les classes KD Tools
            remonte au début de l'année, un an en arrière,{" "}
            <strong className="font-medium">
              soit depuis le{" "}
              {formatDate(startOfYear(subYears(new Date(), 1)), "dateLong")}{" "}
            </strong>{" "}
            pour l'année en cours.
          </p>
          <p className="text-sm">
            Vous pouvez cependant personnaliser cette date :
          </p>
          <p>
            <Input
              type="date"
              className="w-[148px]"
              value={customDate}
              onFocus={(evt) => evt.currentTarget.showPicker()}
              onChange={(e) => {
                setCustomDate(e.currentTarget.value);
              }}
            />
          </p>
        </div>
        <div className="mt-2">
          <Button
            className="w-full"
            disabled={isSaving}
            onClick={handleOnSaveDate}
          >
            Enregistrer
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
