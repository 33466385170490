import { clsx } from "clsx";
import { BotIcon, CrownIcon, MailIcon } from "lucide-react";
import { FC } from "react";

interface AdminUserProps {
  user_id: string;
  email: string;
  discord_username: string | null;
  last_donation_at?: string | null;
  onClick?: (userId: string) => void;
  className?: string;
  size?: "md" | "lg";
}

export const AdminUser: FC<AdminUserProps> = ({
  user_id,
  email,
  discord_username,
  last_donation_at,
  onClick,
  className,
  size = "md",
}) => {
  if (discord_username === null) {
    return (
      <span
        onClick={onClick ? () => onClick(user_id) : undefined}
        className={clsx(
          "inline-flex gap-2 items-center",
          onClick && "cursor-pointer hover:underline underline-offset-2",
          className,
        )}
      >
        <MailIcon
          className={clsx(
            "text-green-500",
            size === "md" ? "w-4 h-4" : "w-5 h-5",
          )}
        />{" "}
        {last_donation_at && (
          <CrownIcon
            className={clsx(
              "text-amber-500",
              size === "md" ? "w-4 h-4" : "w-5 h-5",
            )}
          />
        )}
        <span className={clsx(last_donation_at && "text-amber-500")}>
          {email}
        </span>
      </span>
    );
  }

  return (
    <span
      onClick={onClick ? () => onClick(user_id) : undefined}
      className={clsx(
        "inline-flex gap-2 items-center",
        onClick && "cursor-pointer hover:underline underline-offset-2",
        className,
      )}
    >
      <BotIcon
        className={clsx(
          "text-[#5865F2]",
          size === "md" ? "w-4 h-4" : "w-5 h-5",
        )}
      />{" "}
      {last_donation_at && (
        <CrownIcon
          className={clsx(
            "text-amber-500",
            size === "md" ? "w-4 h-4" : "w-5 h-5",
          )}
        />
      )}
      <span className={clsx(last_donation_at && "text-amber-500")}>
        {discord_username}
      </span>
    </span>
  );
};
