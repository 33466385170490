import { times } from "lodash";

import "~/snow.scss";

export const SnowFall = () => {
  if (new Date().getMonth() !== 11) {
    return null;
  }

  return (
    <div className="fixed top-0 right-0 w-full h-full z-50">
      {times(200).map((snow) => (
        <div key={`snow-${snow}`} className="snow"></div>
      ))}
    </div>
  );
};
