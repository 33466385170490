import clsx from "clsx";
import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { backgroundClassNameFromStanineClass } from "~/routes/suivi/utils/backgroundClassNameFromStanineClass.ts";
import { borderClassNameFromStanineClass } from "~/routes/suivi/utils/borderClassNameFromStanineClass.ts";
import { deserializeExerciseName } from "~/routes/suivi/utils/deserializeExerciseName.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";

interface ExercisesResultsMinMaxAvgProps {
  exercise: string;
  min: number;
  max: number;
  avg: number;
}

export const ExercisesResultsMinMaxAvg: FC<ExercisesResultsMinMaxAvgProps> = ({
  exercise,
  min,
  max,
  avg,
}) => {
  const { settings } = useSettings();

  const { provider } = deserializeExerciseName(exercise);

  const avgValue =
    settings?.table_display === "stanine_with_decimals"
      ? Math.trunc(avg)
      : Math.round(avg);

  return (
    <div className="flex items-center">
      {!settings?.hidden_elements?.includes("data_stanine_min") && (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <p
              className={clsx(
                "-mr-2 pr-2 w-8 h-6 flex items-center justify-center border rounded text-xs opacity-70 bg-white tabular-nums cursor-default",
                textClassNameFromStanineClass(min),
                borderClassNameFromStanineClass(min),
              )}
            >
              {min}
            </p>
          </TooltipTrigger>
          <TooltipContent>
            Classe {provider === "eplTest" ? "EPLtest" : "Pilotest"} la plus
            faible obtenue
          </TooltipContent>
        </Tooltip>
      )}
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <p
            className={clsx(
              "font-mono w-10 text-base text-center rounded z-10 tabular-nums flex items-baseline justify-center cursor-default",
              backgroundClassNameFromStanineClass(avgValue),
            )}
          >
            <span className="font-medium">{avgValue}</span>
            {settings?.table_display === "stanine_with_decimals" && (
              <span className="text-xs">.{Math.round((avg % 1) * 10)}</span>
            )}
          </p>
        </TooltipTrigger>
        <TooltipContent>
          Classe {provider === "eplTest" ? "EPLtest" : "Pilotest"} moyenne
        </TooltipContent>
      </Tooltip>
      {!settings?.hidden_elements?.includes("data_stanine_max") && (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <p
              className={clsx(
                "-ml-2 pl-2 w-8 h-6 flex items-center justify-center border rounded text-xs opacity-70 bg-white tabular-nums cursor-default",
                textClassNameFromStanineClass(max),
                borderClassNameFromStanineClass(max),
              )}
            >
              {max}
            </p>
          </TooltipTrigger>
          <TooltipContent>
            Classe {provider === "eplTest" ? "EPLtest" : "Pilotest"} la plus
            élevée obtenue
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};
