import { snakeCase } from "lodash";
import { FC, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog.tsx";
import { Input } from "~/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { SUIVI_SELECTIONS } from "~/routes/suivi/utils/selections.ts";

export const ChooseSelectionModal: FC = () => {
  const { settings, saveSettings } = useSettings();

  const [selectionName, setSelectionName] = useState("");
  const [selectionCustomName, setSelectionCustomName] = useState("");

  const handleOnSave = () => {
    saveSettings({
      selection:
        selectionName === "other"
          ? `other:${snakeCase(selectionCustomName)}`
          : selectionName,
    });
  };

  const isDisabled =
    selectionName.trim().length === 0 ||
    (selectionName === "other" && selectionCustomName.trim().length === 0);

  if (!settings) {
    return null;
  }

  return (
    <Dialog open={!settings.selection}>
      <DialogContent
        className="max-w-lg"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="inline-flex items-center gap-2">
            Quelle sélection préparez-vous ?
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <p className="text-sm">
            Afin d'améliorer la fiabilité des statistiques affichées sur KD
            Tools, merci de choisir la sélection que vous préparez actuellement.
          </p>
        </div>
        <div>
          <Select
            value={selectionName}
            onValueChange={(selectionName) => setSelectionName(selectionName)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Choisir…" />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(SUIVI_SELECTIONS).map((selectionKey) => (
                <SelectItem
                  key={`selection-${selectionKey}`}
                  value={selectionKey}
                >
                  {SUIVI_SELECTIONS[selectionKey]}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {selectionName === "other" && (
            <Input
              className="mt-2.5"
              placeholder="Saisissez la sélection que vous préparez…"
              value={selectionCustomName}
              onChange={(evt) =>
                setSelectionCustomName(evt.currentTarget.value)
              }
              autoFocus={true}
            />
          )}
        </div>
        <div className="mt-4">
          <Button
            className="w-full"
            disabled={isDisabled}
            onClick={handleOnSave}
          >
            Enregistrer
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
