import clsx from "clsx";
import { ShieldAlert, SparklesIcon } from "lucide-react";
import { FC, ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import { useInterval } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { Button } from "~/components/ui/button.tsx";
import { useMatchingRoute } from "~/hooks/useMatchingRoute.ts";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";

interface WrapperProps {
  children: ReactNode;
  header?: ReactNode;
  extended?: boolean;
  full?: boolean;
  isLoading?: boolean;
  deactivated?: boolean;
}

const WrapperRestricted = () => (
  <div className="h-full flex items-center justify-center">
    <p className="text-center font-medium flex flex-col items-center justify-center space-y-2">
      <ShieldAlert className="text-red-500" size={52} />
      <span>Accès restreint</span>
    </p>
  </div>
);

const WrapperDeactivated = () => (
  <div className="flex flex-col mt-24">
    <p className="mx-auto">
      <SparklesIcon className="text-blue-600 w-12 h-12" />
    </p>
    <p className="text-center text-blue-600 font-medium text-lg mt-3">
      On ne vous arrête plus !
    </p>
    <p className="mt-4 mx-auto max-w-lg text-center">
      Vous en êtes à plus de 100 essais sur cet exercice ! Pour continuer vos
      entrainements, merci de souscrire à KD Tools (15 €/an).
    </p>
    <p className="mt-4 text-center">
      <Button>Souscrire à KD Tools</Button>
    </p>
  </div>
);

export const Wrapper: FC<WrapperProps> = ({
  header,
  children,
  extended = false,
  full = false,
  isLoading = false,
  deactivated = false,
}) => {
  const { canAccess } = useCurrentUser();
  const matchingRoute = useMatchingRoute();

  const [randomKey, setRandomKey] = useState(crypto.randomUUID());

  useInterval(
    () => setRandomKey(crypto.randomUUID()),
    document.getElementById("app-header-actions") === null ? 150 : null,
  );

  if (isLoading) {
    return <LayoutLoading />;
  }

  if (matchingRoute.requireRole && !canAccess(matchingRoute.requireRole)) {
    return <WrapperRestricted />;
  }

  const headerActionsElement = document.getElementById("app-header-actions");

  if (!headerActionsElement) {
    return null;
  }

  return (
    <div
      key={randomKey}
      className={clsx(
        "mx-auto w-full h-full md:px-4",
        full ? "lg:w-11/12" : extended ? "lg:w-10/12" : "lg:w-8/12",
      )}
    >
      <div className="mt-2 h-full">
        {deactivated ? <WrapperDeactivated /> : children}
      </div>
      {header &&
        headerActionsElement &&
        createPortal(header, headerActionsElement)}
    </div>
  );
};
