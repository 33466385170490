import groupBy from "lodash/groupBy";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";

export const sortExerciseNames = (
  allAttempts: ExerciseAttempt[],
  exerciseNames: string[],
  useCustomSort: boolean = false,
) => {
  const allExerciseNames = Object.keys(
    groupBy(allAttempts, (attempt) => attempt.name),
  );

  if (exerciseNames.length === 0) {
    return allExerciseNames.sort();
  }

  const filteredExercises = allExerciseNames.filter((r) =>
    exerciseNames.includes(r),
  );

  if (!useCustomSort) {
    return filteredExercises.sort();
  }

  const nameOrderMap = new Map(
    exerciseNames.map((name, index) => [name, index]),
  );

  return filteredExercises.sort((a, b) => {
    const indexA = nameOrderMap.get(a) ?? exerciseNames.length;
    const indexB = nameOrderMap.get(b) ?? exerciseNames.length;

    return indexA - indexB;
  });
};
