import { noop } from "lodash";
import { HeartHandshakeIcon } from "lucide-react";
import { FC } from "react";
import { useEffectOnce } from "react-use";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";

export const Donate: FC = () => {
  const { user } = useCurrentUser();

  useEffectOnce(() => {
    supabase
      .from("donations")
      .insert({
        user_id: user.id,
      })
      .then(noop);
  });

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <p>
        <HeartHandshakeIcon className="text-blue-600" size={72} />
      </p>
      <h2 className="mt-4 text-2xl font-medium">Merci !</h2>
      <p className="mt-2 max-w-md text-center">
        N'hésitez pas à transmettre vos idées, retours
        <br />
        et pistes d'amélioration via{" "}
        <a
          href="https://forms.fillout.com/t/vJdHp6jTEqus"
          target="_blank"
          className="underline hover:no-underline"
        >
          le formulaire de contact
        </a>{" "}
        !
      </p>
    </div>
  );
};
