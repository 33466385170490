import { set } from "date-fns";
import { useAtom } from "jotai";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce, usePrevious } from "react-use";
import { toast } from "sonner";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { suiviAttempts } from "~/routes/suivi/atoms.ts";
import { useImports } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { deserializeExerciseName } from "~/routes/suivi/utils/deserializeExerciseName.ts";
import { serializeExercise } from "~/routes/suivi/utils/serializeExerciseName.ts";
import {
  sanitizeEplAttemptApi,
  sanitizePilotestAttemptApi,
} from "~/routes/suivi/utils.ts";
import { supabase } from "~/supabase.ts";
import { Enums } from "~/supabase.types.ts";

interface AttemptsState {
  attempts: ExerciseAttempt[];
  isSyncing: boolean;
}

interface AttemptsContextType extends AttemptsState {
  checkConnectivityWithPilotest: (
    email: string,
    password: string,
  ) => Promise<void>;
  checkConnectivityWithEplTest: (apiKey: string) => Promise<void>;
  getResultsFromPilotest: () => Promise<ExerciseAttempt[]>;
  getResultsFromEplTest: () => Promise<ExerciseAttempt[]>;
  getAttempts: () => PromiseLike<ExerciseAttempt[]>;
  saveAttempts: (
    source: Enums<"import_source">,
    provider: Enums<"suivi_provider">,
    newAttempts: ExerciseAttempt[],
  ) => Promise<void>;
  deleteAttempts: (provider: Enums<"suivi_provider">) => Promise<void>;
  refreshAttempts: () => Promise<void>;
  sync: () => Promise<void>;
}

const AttemptsContext = createContext<AttemptsContextType | undefined>(
  undefined,
);

export const useAttempts = () => {
  const context = useContext(AttemptsContext);

  if (context === undefined) {
    throw new Error("useAttempts must be used within an AttemptsProvider");
  }

  return context;
};

interface AttemptsProviderProps {
  children: ReactNode;
}

export const AttemptsProvider: React.FC<AttemptsProviderProps> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { settings } = useSettings();
  const { saveImport } = useImports();
  const { user, accessToken } = useCurrentUser();

  const { hasPilotestAutoSyncEnabled, credentials } = usePilotestCredentials();
  const prevHasPilotestAutoSyncEnabled = usePrevious(
    hasPilotestAutoSyncEnabled,
  );

  const [allAttempts, setAllAttempts] = useAtom(suiviAttempts);
  const [isSyncing, setIsSyncing] = useState(false);

  const checkConnectivityWithPilotest = (email: string, password: string) =>
    fetch("https://api-kd.valentin.xyz/pilotest/results", {
      headers: {
        "X-Supabase-Token": accessToken,
        "X-PT-U": btoa(email),
        "X-PT-P": btoa(password),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }

        return Promise.resolve();
      });

  const checkConnectivityWithEplTest = (apiKey: string) =>
    fetch(
      `https://epltest.fr/public_api/instances?locale=fr&public_api_key=${encodeURIComponent(
        apiKey,
      )}`,
    ).then((res) =>
      res.ok ? Promise.resolve() : Promise.reject("sync:could_not_fetch"),
    );

  const getResultsFromPilotest = async (): Promise<ExerciseAttempt[]> => {
    if (!hasPilotestAutoSyncEnabled || !credentials) {
      return Promise.resolve([]);
    }

    const { email, password } = credentials;

    const res = await fetch("https://api-kd.valentin.xyz/pilotest/results", {
      headers: {
        "X-Supabase-Token": accessToken,
        "X-PT-U": btoa(email),
        "X-PT-P": btoa(password),
      },
    });

    const resJson = await res.json();

    if (resJson.error) {
      return Promise.resolve([]);
    }

    return resJson.map(sanitizePilotestAttemptApi);
  };

  const getResultsFromEplTest = async (): Promise<ExerciseAttempt[]> => {
    if (!settings?.epl_api_key) {
      return Promise.resolve([]);
    }

    try {
      const res = await fetch(
        `https://epltest.fr/public_api/instances?locale=fr&public_api_key=${encodeURIComponent(
          settings.epl_api_key,
        )}`,
      );

      const resJson = await res.json();

      return resJson.map(sanitizeEplAttemptApi);
    } catch {
      return [];
    }
  };

  const getAttempts = async (): Promise<ExerciseAttempt[]> => {
    const { data } = await supabase
      .from("suivi_attempts")
      .select("provider, exercise, percent_score, stanine_class, at")
      .order("at", { ascending: true })
      .eq("user_id", user.id);

    if (!data) {
      return [];
    }

    return data.map(
      (d) =>
        ({
          provider: d.provider,
          name: serializeExercise(d.provider, d.exercise),
          stanineClass: d.stanine_class,
          percentScore: d.percent_score,
          at: new Date(d.at),
        }) as ExerciseAttempt,
    );
  };

  const saveAttempts = async (
    source: Enums<"import_source">,
    provider: Enums<"suivi_provider">,
    newAttempts: ExerciseAttempt[],
  ) =>
    supabase
      .from("suivi_attempts")
      .upsert(
        newAttempts.map((attempt) => {
          const { provider, exercise } = deserializeExerciseName(attempt.name);

          return {
            user_id: user.id,
            provider,
            exercise,
            percent_score: attempt.percentScore,
            stanine_class: attempt.stanineClass,
            at:
              provider === "pilotest"
                ? set(new Date(attempt.at), {
                    seconds: 0,
                    milliseconds: 0,
                  }).toISOString()
                : new Date(attempt.at).toISOString(),
          };
        }),
        { ignoreDuplicates: true },
      )
      .select()
      .then(({ data }) => {
        if ((data?.length ?? 0) > 0) {
          toast.success(
            `${data?.length} résultat(s) ${provider === "pilotest" ? "Pilotest" : "EPLtest"}`,
            {
              id: `saveAttempts-${provider}-completed`,
              description: `ajoutés sur KD Tools`,
              duration: 10000,
            },
          );
        }

        return saveImport(source, provider, data?.length);
      });

  const deleteAttempts = async (provider: Enums<"suivi_provider">) =>
    supabase
      .from("suivi_attempts")
      .delete()
      .eq("user_id", user.id)
      .eq("provider", provider)
      .then(refreshAttempts);

  const refreshAttempts = async () => setAllAttempts(await getAttempts());

  const sync = async () => {
    setIsSyncing(true);

    const syncPromises: Promise<unknown>[] = [];

    if (hasPilotestAutoSyncEnabled && credentials) {
      syncPromises.push(
        saveAttempts("auto_sync", "pilotest", await getResultsFromPilotest()),
      );
    }

    if (settings?.epl_api_key) {
      syncPromises.push(
        saveAttempts("auto_sync", "eplTest", await getResultsFromEplTest()),
      );
    }

    await Promise.all(syncPromises);

    setAllAttempts(await getAttempts());

    setIsSyncing(false);
  };

  useEffectOnce(() => {
    refreshAttempts().then(sync);
  });

  // This one is used to get Pilotest results when auto-sync is enabled…
  useEffect(() => {
    if (
      prevHasPilotestAutoSyncEnabled === false &&
      hasPilotestAutoSyncEnabled
    ) {
      sync();
    }
  }, [hasPilotestAutoSyncEnabled, prevHasPilotestAutoSyncEnabled]);

  useEffect(() => {
    if (searchParams.has("forceSync")) {
      sync().then(() => {
        searchParams.delete("forceSync");
        setSearchParams(searchParams);
      });
    }
  }, [searchParams, setSearchParams]);

  if (allAttempts === null) {
    return <LayoutLoading />;
  }

  return (
    <AttemptsContext.Provider
      value={{
        attempts: allAttempts,
        isSyncing,
        checkConnectivityWithPilotest,
        checkConnectivityWithEplTest,
        getResultsFromPilotest,
        getResultsFromEplTest,
        getAttempts,
        saveAttempts,
        deleteAttempts,
        refreshAttempts,
        sync,
      }}
    >
      {children}
    </AttemptsContext.Provider>
  );
};
