import { startOfYear, subYears } from "date-fns";
import { keyBy } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { StanineKdToolsModal } from "~/routes/suivi/components/StanineKdToolsModal.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { Stanine } from "~/routes/suivi/types/Stanine.ts";
import { serializeExercise } from "~/routes/suivi/utils/serializeExerciseName.ts";
import { supabase } from "~/supabase.ts";

interface StaninesType {
  stanines: Stanine[] | null;
  staninesList: Record<string, Stanine>;
  getStanineForExercise: (
    exerciseName: string,
    percentageScore: number,
  ) => number | null;
  showStanineModal: (show: boolean) => void;
}

const StaninesContext = createContext<StaninesType | undefined>(undefined);

export const useStanines = () => {
  const context = useContext(StaninesContext);

  if (context === undefined) {
    throw new Error("useStanines must be used within an StaninesProvider");
  }

  return context;
};

interface StaninesProviderProps {
  children: ReactNode;
}

export const StaninesProvider: React.FC<StaninesProviderProps> = ({
  children,
}) => {
  const { settings } = useSettings();

  const [stanines, setStanines] = useState<Stanine[] | null>(null);
  const [staninesList, setStaninesList] = useState<Record<string, Stanine>>({});

  const [showModal, setShowModal] = useState(false);

  const refreshStanines = useCallback(async () => {
    return supabase
      .rpc("public_get_stanines", {
        from_date:
          settings?.stanine_from_date ??
          startOfYear(subYears(new Date(), 1)).toDateString(),
        // until_date: "2025-11-01",
      })
      .gt("attempts", 42)
      .then(({ data }) => {
        const stanines =
          data?.map((d) => ({
            ...d,
            exercise: serializeExercise(d.provider, d.exercise),
          })) ?? [];

        setStanines(stanines);
        setStaninesList(keyBy(stanines, "exercise"));
      });
  }, [settings?.stanine_from_date]);

  const getStanineForExercise = useCallback(
    (exerciseName: string, percentageScore: number): number | null => {
      const stanine = staninesList[exerciseName] ?? null;

      if (stanine === null) {
        return null;
      }

      if (percentageScore >= stanine.s8_upper_bound) return 9;
      if (percentageScore >= stanine.s7_upper_bound) return 8;
      if (percentageScore >= stanine.s6_upper_bound) return 7;
      if (percentageScore >= stanine.s5_upper_bound) return 6;
      if (percentageScore >= stanine.s4_upper_bound) return 5;
      if (percentageScore >= stanine.s3_upper_bound) return 4;
      if (percentageScore >= stanine.s2_upper_bound) return 3;
      if (percentageScore >= stanine.s1_upper_bound) return 2;

      return 1;
    },
    [staninesList],
  );

  useEffect(() => {
    refreshStanines();
  }, [refreshStanines]);

  if (stanines === null) {
    return <LayoutLoading />;
  }

  return (
    <StaninesContext.Provider
      value={{
        stanines,
        staninesList,
        getStanineForExercise,
        showStanineModal: setShowModal,
      }}
    >
      {children}
      <StanineKdToolsModal
        open={showModal}
        onDismiss={() => setShowModal(false)}
      />
    </StaninesContext.Provider>
  );
};
