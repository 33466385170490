import { InfoCircledIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { startOfYear, subYears } from "date-fns";
import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { StanineCurve } from "~/routes/suivi/components/StanineCurve.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { useStanines } from "~/routes/suivi/providers/StaninesProvider.tsx";
import { borderClassNameFromStanineClass } from "~/routes/suivi/utils/borderClassNameFromStanineClass.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";
import { formatDate } from "~/utils/formatDate.ts";
import { formatNumber } from "~/utils/formatNumber.ts";

interface ExercisesResultsKdToolsStanineProps {
  name: string;
  percentage: number;
}

export const ExercisesResultsKdToolsStanine: FC<
  ExercisesResultsKdToolsStanineProps
> = ({ name, percentage }) => {
  const { settings } = useSettings();
  const { staninesList, getStanineForExercise, showStanineModal } =
    useStanines();

  if (settings?.hidden_elements?.includes("data_stanine_kd_tools")) {
    return null;
  }

  const stanineKdTools = getStanineForExercise(name, percentage);

  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger asChild>
        <div
          className={clsx(
            "hidden sm:flex w-8 h-6 bg-white text-xs text-center rounded z-10 tabular-nums border border-dashed items-center justify-center shrink-0 cursor-default",
            stanineKdTools === null && "border-slate-500 text-slate-300",
            stanineKdTools !== null &&
              borderClassNameFromStanineClass(stanineKdTools),
            stanineKdTools !== null &&
              textClassNameFromStanineClass(stanineKdTools),
          )}
        >
          <span className="font-light">
            {stanineKdTools !== null ? stanineKdTools : "–"}
          </span>
        </div>
      </TooltipTrigger>
      {stanineKdTools === null ? (
        <TooltipContent className="inline-flex items-center gap-1">
          Résultats insuffisants depuis le{" "}
          {formatDate(
            settings?.stanine_from_date ?? startOfYear(subYears(new Date(), 1)),
            "dateLong",
          )}{" "}
          pour calculer une classe KD Tools pertinente{" "}
          <InfoCircledIcon
            className="ml-1 w-4 h-4 cursor-pointer"
            onClick={() => showStanineModal(true)}
          />
        </TooltipContent>
      ) : (
        <TooltipContent className="w-[396px] mr-1 -ml-2" side="left">
          <StanineCurve
            stanines={staninesList[name]}
            highlight={stanineKdTools}
            height={128}
          />
          <p className="text-center leading-5 mt-1.5 mb-1">
            Classe KD Tools basée sur{" "}
            <strong className="font-medium">
              {formatNumber(staninesList[name].attempts)} essais
            </strong>{" "}
            depuis le{" "}
            {formatDate(
              settings?.stanine_from_date ??
                startOfYear(subYears(new Date(), 1)),
              "dateLong",
            )}
            <br />
            pour un{" "}
            <strong className="font-medium">
              score de {percentage} %
            </strong>{" "}
            <InfoCircledIcon
              className="inline-block -mt-1 ml-1 w-4 h-4 cursor-pointer"
              onClick={() => showStanineModal(true)}
            />
          </p>
        </TooltipContent>
      )}
    </Tooltip>
  );
};
