import { format } from "date-fns";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";

import { Drawer } from "~/components/Drawer.tsx";
import { Button } from "~/components/ui/button.tsx";
import { AdminBlock } from "~/routes/admin/components/AdminBlock.tsx";
import { AdminUser } from "~/routes/admin/components/AdminUser.tsx";
import IconEpl from "~/routes/suivi/imgs/epl-icon.png";
import IconPilotest from "~/routes/suivi/imgs/pt-icon.png";
import { SUIVI_SELECTIONS } from "~/routes/suivi/utils/selections.ts";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";
import { formatDate } from "~/utils/formatDate.ts";
import { formatNumber } from "~/utils/formatNumber.ts";

interface AdminDrawerUserProps {
  userId: string | null;
  onDismiss: () => void;
}

const AdminDrawerUserHeader = ({
  label,
  value,
}: {
  label: string;
  value?: ReactNode;
}) => {
  return (
    <div className="flex items-center gap-2">
      <div className="w-4/12 text-right text-[10px] uppercase font-semibold text-slate-400">
        {label}
      </div>
      <div className="w-8/12 text-sm tabular-nums">{value ?? "–"}</div>
    </div>
  );
};

export const AdminDrawerUser: FC<AdminDrawerUserProps> = ({
  userId,
  onDismiss,
}) => {
  const [user, setUser] = useState<Tables<"profiles"> | null>(null);
  const [lastImports, setLastImports] = useState<
    Tables<"suivi_imports">[] | null
  >(null);
  const [lastAttempts, setLastAttempts] = useState<
    Tables<"suivi_attempts">[] | null
  >(null);
  const [lastScores, setLastScores] = useState<Tables<"scores">[] | null>(null);
  const [suiviSettings, setSuiviSettings] =
    useState<Tables<"suivi_settings"> | null>(null);
  const [renamings, setRenamings] = useState<
    Tables<"suivi_renamings">[] | null
  >(null);
  const [flagged, setFlagged] = useState<
    Tables<"suivi_flagged_exercises">[] | null
  >(null);
  const [groups, setGroups] = useState<Tables<"suivi_groups">[] | null>(null);
  const [dimensions, setDimensions] = useState<string[] | null>(null);
  const [donations, setDonations] = useState<Tables<"donations">[] | null>(
    null,
  );
  const [counts, setCounts] = useState({ imports: 0, attempts: 0, scores: 0 });

  const getLastImports = useCallback(
    (limit = 5) => {
      if (!userId) {
        return;
      }

      supabase
        .from("suivi_imports")
        .select()
        .eq("user_id", userId)
        .order("at", { ascending: false })
        .limit(limit)
        .then(({ data }) => setLastImports(data ?? []));
    },
    [userId],
  );

  const getLastAttempts = useCallback(
    (limit = 10) => {
      if (!userId) {
        return;
      }

      supabase
        .from("suivi_attempts")
        .select()
        .eq("user_id", userId)
        .order("at", { ascending: false })
        .limit(limit)
        .then(({ data }) => setLastAttempts(data ?? []));
    },
    [userId],
  );

  const getLastScores = useCallback(
    (limit = 10) => {
      if (!userId) {
        return;
      }

      supabase
        .from("scores")
        .select()
        .eq("user_id", userId)
        .order("at", { ascending: false })
        .limit(limit)
        .then(({ data }) => setLastScores(data ?? []));
    },
    [userId],
  );

  const getSuiviSettings = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("suivi_settings")
      .select()
      .eq("user_id", userId)
      .maybeSingle()
      .then(({ data }) => setSuiviSettings(data ?? null));
  }, [userId]);

  const getRenamings = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("suivi_renamings")
      .select()
      .eq("user_id", userId)
      .then(({ data }) => setRenamings(data ?? []));
  }, [userId]);

  const getFlaggedExercises = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("suivi_flagged_exercises")
      .select()
      .eq("user_id", userId)
      .then(({ data }) => setFlagged(data ?? []));
  }, [userId]);

  const getGroups = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("suivi_groups")
      .select()
      .eq("user_id", userId)
      .order("created_at", { ascending: true })
      .then(({ data }) => setGroups(data ?? []));
  }, [userId]);

  const getDimensions = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("suivi_dimensions")
      .select("*, group:suivi_groups(name, exercises)")
      .eq("user_id", userId)
      .order("created_at", { ascending: true })
      .then(({ data }) => setDimensions(data?.map((d) => d.group!.name) ?? []));
  }, [userId]);

  const getDonations = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("donations")
      .select()
      .eq("user_id", userId)
      .order("created_at", { ascending: true })
      .then(({ data }) => setDonations(data ?? []));
  }, [userId]);

  const getUser = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("profiles")
      .select()
      .eq("user_id", userId)
      .single()
      .then(({ data }) => setUser(data ?? null));
  }, [userId]);

  const getCounts = useCallback(() => {
    if (!userId) {
      return;
    }

    supabase
      .from("suivi_imports")
      .select("*", { count: "exact", head: true })
      .eq("user_id", userId)
      .then(({ count }) => {
        setCounts((prevCounts) => ({ ...prevCounts, imports: count ?? 0 }));
      });

    supabase
      .from("suivi_attempts")
      .select("*", { count: "exact", head: true })
      .eq("user_id", userId)
      .then(({ count }) => {
        setCounts((prevCounts) => ({ ...prevCounts, attempts: count ?? 0 }));
      });

    supabase
      .from("scores")
      .select("*", { count: "exact", head: true })
      .eq("user_id", userId)
      .then(({ count }) => {
        setCounts((prevCounts) => ({ ...prevCounts, scores: count ?? 0 }));
      });
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    getUser();
    getLastImports();
    getLastAttempts();
    getLastScores();
    getSuiviSettings();
    getRenamings();
    getFlaggedExercises();
    getGroups();
    getDimensions();
    getDonations();
    getCounts();
  }, [userId]);

  return (
    <Drawer
      open={!!userId}
      title={user ? <AdminUser {...user} className="text-xl" size="lg" /> : ""}
      onDismiss={onDismiss}
      blurBackground={true}
    >
      {user && (
        <>
          <div className="flex flex-col gap-2 border-2 rounded py-3">
            <AdminDrawerUserHeader label="ID" value={user?.user_id} />
            <AdminDrawerUserHeader
              label="Inscrit le"
              value={formatDate(user.created_at)}
            />
            <AdminDrawerUserHeader label="Email" value={user?.email} />
            {user.discord_username && (
              <AdminDrawerUserHeader
                label="Discord"
                value={user?.discord_username}
              />
            )}
            {(donations?.length ?? 0) > 0 && (
              <AdminDrawerUserHeader
                label="Dons"
                value={`${donations?.length} don(s) réalisé(s) : ${donations?.map((donation) => formatDate(donation.created_at)).join(", ")}.`}
              />
            )}
          </div>
          <div className="flex flex-col gap-6 my-6">
            {suiviSettings && (
              <AdminBlock
                title="Outil de Suivi"
                subtitle={`Activé le ${format(new Date(suiviSettings?.created_at), "dd/MM/yy HH:mm")}`}
              >
                <div className="flex flex-col gap-2 px-4 py-3">
                  {suiviSettings?.display_from_date && (
                    <AdminDrawerUserHeader
                      label="Depuis"
                      value={formatDate(
                        suiviSettings.display_from_date,
                        "dateLong",
                      )}
                    />
                  )}
                  {suiviSettings?.selection && (
                    <AdminDrawerUserHeader
                      label="Sélection"
                      value={
                        SUIVI_SELECTIONS[suiviSettings.selection] ??
                        suiviSettings.selection
                      }
                    />
                  )}
                  {suiviSettings.epl_api_key && (
                    <AdminDrawerUserHeader
                      label="Clé API EPLtest"
                      value={suiviSettings?.epl_api_key}
                    />
                  )}
                  {(suiviSettings?.hidden_elements?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Élèments Cachés"
                      value={suiviSettings?.hidden_elements?.join(", ")}
                    />
                  )}
                  {suiviSettings?.table_display && (
                    <AdminDrawerUserHeader
                      label="Affichage Progression"
                      value={suiviSettings?.table_display}
                    />
                  )}
                  {suiviSettings?.graph_display && (
                    <AdminDrawerUserHeader
                      label="Affichage Graphs"
                      value={suiviSettings?.graph_display}
                    />
                  )}
                  {suiviSettings?.graph_compute && (
                    <AdminDrawerUserHeader
                      label="Calcul Graphs"
                      value={suiviSettings?.graph_compute}
                    />
                  )}
                  {(renamings?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Renommages"
                      value={renamings
                        ?.map((ren) => ren.exercise_renamed)
                        .join(", ")}
                    />
                  )}
                  {(flagged?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Highlights"
                      value={flagged?.map((flag) => flag.name).join(", ")}
                    />
                  )}
                  {(groups?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Groupes"
                      value={groups?.map((group) => group.name).join(", ")}
                    />
                  )}
                  {suiviSettings?.current_group_id && (
                    <AdminDrawerUserHeader
                      label="Groupe Sélectionné"
                      value={
                        groups?.find(
                          (g) => g.id === suiviSettings.current_group_id,
                        )?.name ?? "–"
                      }
                    />
                  )}
                  {(dimensions?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Dimensions"
                      value={dimensions?.join(", ")}
                    />
                  )}
                </div>
              </AdminBlock>
            )}
            {(lastImports?.length ?? 0) > 0 && (
              <AdminBlock
                title="Derniers Imports"
                subtitle={`${formatNumber(counts.imports)} imports réalisés`}
              >
                <table className="w-full">
                  <tbody>
                    {lastImports?.map((anImport, idx) => (
                      <tr key={`import-${idx}`} className="even:bg-slate-50">
                        <td className="text-right tabular-nums text-sm px-2 py-1 w-3/12">
                          {formatDate(anImport.at)}
                        </td>
                        <td className="px-2 text-sm inline-flex items-center gap-2">
                          <img
                            alt="Icon"
                            src={
                              anImport.provider === "eplTest"
                                ? IconEpl
                                : IconPilotest
                            }
                            className="w-4 h-4"
                          />
                          {anImport.source}
                        </td>
                        <td className="px-2 text-sm text-right font-mono tabular-nums">
                          +{formatNumber(anImport.attempts_added)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {counts.imports > (lastImports?.length ?? 0) && (
                  <div className="text-center py-1">
                    <Button
                      size="xs"
                      variant="ghost"
                      onClick={() =>
                        getLastImports((lastImports?.length ?? 5) + 5)
                      }
                    >
                      Voir plus
                    </Button>
                  </div>
                )}
              </AdminBlock>
            )}
            {(lastAttempts?.length ?? 0) > 0 && (
              <AdminBlock
                title="Derniers Résultats"
                subtitle={`${formatNumber(counts.attempts)} essais réalisés`}
              >
                <table className="w-full">
                  <tbody>
                    {lastAttempts?.map((attempt, idx) => (
                      <tr key={`import-${idx}`} className="even:bg-slate-50">
                        <td className="text-right tabular-nums text-sm px-2 py-1 w-3/12">
                          {formatDate(attempt.at)}
                        </td>
                        <td className="px-2 text-sm inline-flex items-center gap-2">
                          <img
                            alt="Icon"
                            src={
                              attempt.provider === "eplTest"
                                ? IconEpl
                                : IconPilotest
                            }
                            className="w-4 h-4"
                          />{" "}
                          {attempt.exercise}
                        </td>
                        <td className="px-2 text-sm tabular-nums font-mono text-right">
                          <span className="text-xs">
                            ({attempt.percent_score}%)
                          </span>{" "}
                          <span className="font-semibold">
                            {attempt.stanine_class}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {counts.attempts > (lastAttempts?.length ?? 0) && (
                  <div className="text-center py-1">
                    <Button
                      size="xs"
                      variant="ghost"
                      onClick={() =>
                        getLastAttempts((lastAttempts?.length ?? 10) + 10)
                      }
                    >
                      Voir plus
                    </Button>
                  </div>
                )}
              </AdminBlock>
            )}
            {(lastScores?.length ?? 0) > 0 && (
              <AdminBlock
                title="Derniers Scores"
                subtitle={`${formatNumber(counts.scores)} exercices réalisés`}
              >
                <table className="w-full">
                  <tbody>
                    {lastScores?.map((score, idx) => (
                      <tr key={`import-${idx}`} className="even:bg-slate-50">
                        <td className="text-right tabular-nums text-sm px-2 py-1 w-3/12">
                          {formatDate(score.at)}
                        </td>
                        <td className="px-2 text-sm">
                          {score.exercise}{" "}
                          <span className="text-slate-400">
                            {score.variant}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {counts.scores > (lastScores?.length ?? 0) && (
                  <div className="text-center py-1">
                    <Button
                      size="xs"
                      variant="ghost"
                      onClick={() =>
                        getLastScores((lastScores?.length ?? 10) + 10)
                      }
                    >
                      Voir plus
                    </Button>
                  </div>
                )}
              </AdminBlock>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};
