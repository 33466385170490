import { SparklesIcon } from "lucide-react";
import { useCallback, useEffect } from "react";
import { toast } from "sonner";

import { useTabState } from "~/hooks/useTabState.ts";

export const useLatestVersionCheck = () => {
  const { isTabActive, tabStateCurrent, tabStatePrevious } = useTabState();

  const getVersion = useCallback(async () => {
    const response = await fetch("/version.json", { cache: "no-store" });
    const data = await response.json();
    return data.version;
  }, []);

  const getInitialVersionCallback = useCallback(async () => {
    localStorage.setItem("global:version", await getVersion());
  }, [getVersion]);

  const checkVersionCallback = useCallback(
    async (skipNotify = false) => {
      if (!isTabActive) {
        return;
      }

      const updatedVersion = await getVersion();

      const currentVersion = localStorage.getItem("global:version");
      if (currentVersion && currentVersion !== updatedVersion) {
        if (skipNotify) {
          window.location.reload();
        } else {
          toast(
            <div className="text-sm w-[312px]">
              <p className="font-semibold text-lg text-blue-600 inline-flex items-center gap-1.5">
                <SparklesIcon size={18} />
                <span className="ml-1">Mise à jour disponible !</span>
              </p>
              <p className="text-sm text-slate-500 mt-1">
                Rafraichissez la page pour bénéficier
                <br />
                des dernières fonctionnalités de KD Tools.
              </p>
            </div>,
            {
              id: "global:updateAvailable",
              position: "top-center",
              className: "w-fit",
              duration: Infinity,
            },
          );
        }
      }

      localStorage.setItem("global:version", updatedVersion);
    },
    [getVersion, isTabActive],
  );

  useEffect(() => {
    getInitialVersionCallback();
  }, [getInitialVersionCallback]);

  useEffect(() => {
    const interval = setInterval(checkVersionCallback, 5 * 60 * 1000); // Checking every 5 minutes…

    return () => clearInterval(interval);
  }, [checkVersionCallback]);

  useEffect(() => {
    if (
      tabStatePrevious &&
      tabStatePrevious !== "visible" &&
      tabStateCurrent === "visible"
    ) {
      checkVersionCallback(true);
    }
  }, [checkVersionCallback, tabStateCurrent, tabStatePrevious]);
};
